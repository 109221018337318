import { Nullable } from '../../../typescript'
import { Engine } from '../engine/Engine.type'
import { Entity } from '../entity'
import { HasPlayerId } from './has_player_id'
import { Player } from './Player.type'

export default function getEntityPlayer(engine: Engine, entity: Entity): Nullable<Player> {
  const player_id = (entity as HasPlayerId).player_id
  if (player_id) {
    const players = engine.state.players
    // console.log('getEntityPlayer1', player_id, players.find(p => p.id === player_id))

    for (let i = 0, len = players.length; i < len; i++) {
      // console.log('getEntityPlayer2', player_id, players[i])
      if (player_id === players[i].id) {
        // console.log('getEntityPlayer3', 'yay return')
        return players[i]
      }
    }

    console.error(players)
    throw new Error(`unexpected missing player ID: ${player_id}`)
  }
  // console.log('getEntityPlayer4', 'END OF LOOP')
  return null
}

import { Component, createEffect, createSignal, Match, Switch, useContext } from 'solid-js'
import type { Engine } from '../../lib/core/engine/Engine.type'
import { samePosition } from '../../lib/core/tile_position_xy/samePosition'
import { deepClone } from '../../lib/deep_clone'
import EngineContext from '../EngineContext'
import AttackPositionCornerWidget from './AttackPositionCornerWidget'
import PositionStackStats from './PositionStackStats'

const TopLeftEngineToolCornerWidget: Component = () => {
  const engine : Engine = useContext(EngineContext)
  const [isExpanded, setIsExpanded] = createSignal<boolean>(false)
  let prevSelectedPosition : Engine['selectedPosition'] = null
  createEffect(() => {
    const nextSelectedPosition = engine.selectedPosition
    if (nextSelectedPosition && !samePosition(nextSelectedPosition, prevSelectedPosition)) {
      setIsExpanded(false)
    }
    prevSelectedPosition = nextSelectedPosition
  })
  createEffect(() => {
    console.log('engine.selectedPosition', deepClone(engine.selectedPosition))
    console.log('engine.selectedPositionEnts', deepClone(engine.selectedPositionEnts))
  })
  createEffect(() => {
    console.log('engine.draftMove?.attackEstimates', deepClone(engine.draftMove?.attackEstimates))
  })

  return (
    <Switch>
      <Match when={engine.draftMove?.attackEstimates.length}>
        <AttackPositionCornerWidget/>
      </Match>
      <Match when={engine.selectedPosition}>
        <div class="cw cw-sp float-end m-2" classList={{'expanded': isExpanded()}} onClick={() => setIsExpanded(!isExpanded())}>
          <PositionStackStats ents={engine.selectedPositionEnts} x={isExpanded()} />
        </div>
      </Match>
    </Switch>
  )
}

export default TopLeftEngineToolCornerWidget

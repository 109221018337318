import { Component, createMemo, createSignal, Match, Show, Switch, useContext } from 'solid-js'
import type { Engine } from '../../lib/core/engine/Engine.type'
import getPerTurnIncomeFromPlayerEnts from '../../lib/core/entity/getPerTurnIncomeFromPlayerEnts'
import getPlayerEntities from '../../lib/core/entity/getPlayerEntities'
import type { Player } from '../../lib/core/player/Player.type'
import { PlayerTurnStatus } from '../../lib/core/player/PlayerTurnStatus'
import { immutableEmptyArray } from '../../lib/lib'
import type { Nullable } from '../../typescript'
import EngineContext from '../EngineContext'
import PerTurnIncome from './PerTurnIncome'
import PlayerMoneyCornerWidget from './PlayerMoneyCornerWidget'

const OtherTurnPlayerCornerWidget: Component = () => {
  const engine : Engine = useContext(EngineContext)

  // "other turn player"
  // the player whose turn it is
  // when it is not your turn
  const otherTurnPlayer = createMemo<Nullable<Player>>((): Nullable<Player> => {
    const { authPlayerId } = engine
    const { players, turnPlayerId } = engine.state
    if (authPlayerId === turnPlayerId) {
      return null
    }
    return turnPlayerId ? players.find((p) => p.id === turnPlayerId) : null
  })

  const playerEnts = createMemo(() => {
    const playerId = otherTurnPlayer()?.id
    if (playerId) {
      return getPlayerEntities(engine.state.ents, playerId)
    }
    return immutableEmptyArray as unknown as ReturnType<typeof getPlayerEntities>
  })

  const perTurnIncome = createMemo<number>(() => {
    return getPerTurnIncomeFromPlayerEnts(playerEnts())
  })

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [isBusy, setIsBusy] = createSignal<boolean>(false)
  const [isMoneyWidgetOpen, setIsMoneyWidgetOpen] = createSignal<boolean>(false)

  return (
    <Show when={otherTurnPlayer()} children={(player) => (
      <>
        <div class="cw cw-player2">
          <div class="text-break">{player().dname || player().dname_placeholder}</div>
          <div class="d-flex justify-content-between">
            <Switch>
              <Match when={player().turn_status === PlayerTurnStatus.Done }>
                <span class="fs-6">Done</span>
              </Match>
              <Match when={player().turn_status === PlayerTurnStatus.Pending }>
                <span class="fs-6">Pending</span>
              </Match>
              <Match when={player().turn_status === PlayerTurnStatus.Playing }>
                <span class="fs-6">Playing</span>
              </Match>
              <Match when={player().turn_status === PlayerTurnStatus.Waiting }>
                <span class="fs-6">Waiting</span>
              </Match>
            </Switch>
          </div>
          <PerTurnIncome value={perTurnIncome()} player={player()} onClick={() => {
            setIsMoneyWidgetOpen(!isMoneyWidgetOpen())
          }}/>
        </div>
        <PlayerMoneyCornerWidget class="player2" when={isMoneyWidgetOpen()} setOpen={setIsMoneyWidgetOpen} player={player()}/>
      </>
    )}/>
  )
}

export default OtherTurnPlayerCornerWidget

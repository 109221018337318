import { modifyMutable, produce } from 'solid-js/store'
import calculateDraftMoveAttackEstimates from '../../core/draft_move/calculateDraftMoveAttackEstimates'
import setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition from '../../core/draft_move/setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition'
import { Engine } from '../../core/engine/Engine.type'
import calculateSelectedPositionEnts from '../../core/entity/calculateSelectedPositionEnts'
import { createMoveUnitAction } from '../../core/state/flux/action/Game/MoveUnitAction'
import dispatchClient from '../../core/state/flux/dispatchClient'
import { positionFromPixels } from '../../core/tile_position_xy/positionFromPixels'
import { samePosition } from '../../core/tile_position_xy/samePosition'
import onWindowEvent from '../../onWindowEvent'

export default function registerMouseDownEvent(engine: Engine) {
  function onMouseDown(event: MouseEvent) {
    console.log('onMouseDown', event)
    // const isMap = wasEventTargetMap(event)
    // modifyMutable(
    //   engine,
    //   produce((engine: Engine) => {
    const mouseDownPosition = positionFromPixels(
      engine.state,
      engine.viewCtx,
      event.offsetX,
      event.offsetY
    )
    const { draftMove } = engine
    if (draftMove) {
      // console.log('onMouseDown.draftMove')
      if (samePosition(mouseDownPosition, draftMove.startPosition)) {
        // console.log('samePosition(mouseDownPosition, draftMove.startPosition)')
      } else if (!draftMove.destPosition) {
        // console.log('mouse_down.draftMove.!draftMove.destPosition')
        modifyMutable(engine, produce((engine) => {
          setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition(engine, draftMove, mouseDownPosition)
          calculateDraftMoveAttackEstimates(engine)
        }))
        // if it's an attack, they had the estimates already, let's move forward
        if (engine.draftMove?.attackPosition) {
          dispatchClient(engine, createMoveUnitAction(draftMove))
        }
        // else {
        // // else they were probably picking a specific tile to attack from
        //}
      } else if (!draftMove.attackPosition) {
        // console.log('mouse_down.draftMove.!draftMove.attackPosition')
        // draftMove.attackPosition = mouseDownPosition
      } else {
        // console.log('mouse_down.draftMove.draftMove.destPosition && draftMove.attackPosition')
        // draftMove.attackPosition = mouseDownPosition
      }
    } else {
      // console.log('onMouseDown.!draftMove')
      engine.selectedPosition = null
      calculateSelectedPositionEnts(engine)
    }
    engine.mouseDownEvent = event
    if (!samePosition(engine.mouseDownPosition, mouseDownPosition)) {
      engine.mouseDownPosition = mouseDownPosition
    }
    engine.mouseUpEvent = null
    engine.mouseDragList = []
  }

  onWindowEvent('mousedown', onMouseDown)
}

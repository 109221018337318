import { modifyMutable, produce } from 'solid-js/store'
import { Engine } from '../../core/engine/Engine.type'
import resetEngineToDefaultSelectedTool from '../../core/engine/resetEngineToDefaultSelectedTool'
import getTransportableDestPositionEntity from '../../core/entity/getTransportableDestPositionEntity'
import { SelectedToolId } from '../../core/map_editor/SelectedToolId.enum'
import { createMoveUnitAction } from '../../core/state/flux/action/Game/MoveUnitAction'
import dispatchClient from '../../core/state/flux/dispatchClient'
import onWindowEvent from '../../onWindowEvent'
import preventDefault from './prevent_default'

export default function registerGamePlayKeyboardEvents(engine: Engine, isGamePlay: boolean) {
  // const dirty = engine.cache[1]
  // const ab = createAlertBagStore()
  function onGamePlayKeyDown(event: KeyboardEvent) {
    // console.log('onGamePlayKeyDown', event)
    if (shouldIgnoreHotKeys(event)) {
      return
    }
    // if (event.key === 's') {
    //   if (event.metaKey || event.ctrlKey) {
    //     // console.log('Save Hotkey!')
    //     preventDefault(event)
    //     saveStateChanges(engine)
    //     dispatchClient(engine, createSetStateAction(engine.state))
    //     return
    //   }
    // }
    if (isGamePlay) {
      // if (event.key === 'z') {
      //   if (event.metaKey || event.ctrlKey) {
      //     preventDefault(event)
      //     if (event.shiftKey) {
      //       // console.log('Redo Event!')
      //     } else {
      //       // console.log('Undo Event!')
      //     }
      //     return
      //   }
      // }
      // if (event.key === 'p') {
      //   preventDefault(event)
      //   engine.selectedTool = SelectedToolId.Paint
      //   return
      // }
      // if (event.key === 'e') {
      //   preventDefault(event)
      //   engine.selectedTool = SelectedToolId.Erase
      //   return
      // }
      if (event.key === 'f') {
        preventDefault(event)
        engine.selectedTool = SelectedToolId.File
        return
      }
      if (event.key === 'l') {
        preventDefault(event)
        engine.selectedTool = SelectedToolId.Players
        return
      }
      if (event.key === 'i') {
        preventDefault(event)
        engine.selectedTool = SelectedToolId.Inspect
        return
      }
      if (event.key === 'Escape') {
        console.log('[esc] pressed')
        preventDefault(event)
        modifyMutable(
          engine,
          produce((engine) => {
            resetEngineToDefaultSelectedTool(engine)
          })
        )
        return
      }
      if (event.key === 'w') {
        console.log('[w] pressed')
        preventDefault(event)
        if (engine.draftMove) {
          dispatchClient(engine, createMoveUnitAction({...engine.draftMove, wait: true }))
        }
        return
      }
      if (event.key === 'p') {
        console.log('[p] pressed')
        preventDefault(event)
        const cargoEnt = getTransportableDestPositionEntity(engine)
        if (engine.draftMove) {
          if (cargoEnt) {
            dispatchClient(engine, createMoveUnitAction({...engine.draftMove, pickup: cargoEnt }))
          }
        }
        return
      }
      if (event.key === 'a') {
        console.log('[a] pressed')
        preventDefault(event)
        if (engine.draftMove) {
          dispatchClient(engine, createMoveUnitAction({ ...engine.draftMove, annex: true }))
        }
        return
      }
      // if (event.key === 'Enter') {
      //   console.log('[enter] pressed')
      //   preventDefault(event)
      //   if (engine.draftMove) {
      //     dispatchClient(engine, createMoveUnitAction(engine.draftMove))
      //   }
      //   return
      // }
    }

    // when it's possible for Ctrl - Z to undo this
    // if (event.key === 'r') {
    //   if (event.shiftKey && (event.metaKey || event.ctrlKey)) {
    //     console.log('Revert to PrevState!')
    //     preventDefault(event)
    //     engine.state = structuredClone(unwrap(engine.prevState))
    //     return
    //   }
    // }
  }

  function onGamePlayKeyUp() {
    // console.log('onGamePlayKeyUp', event)
    // if (event.key === 's') {
    //   if (event.metaKey || event.ctrlKey) {
    //     console.log('Save Hotkey!')
    //     preventDefault(event)
    //     saveStateChanges(engine)
    //     return
    //   }
    // }
  }

  onWindowEvent('keyup', onGamePlayKeyUp)
  onWindowEvent('keydown', onGamePlayKeyDown)
}

function shouldIgnoreHotKeys(event: KeyboardEvent): boolean {
  if (event.metaKey || event.ctrlKey) {
    return false
  }

  const target = event.target as HTMLElement | null
  const targetNodeName = target?.nodeName
  // console.log('targetNodeName', targetNodeName)
  return (targetNodeName === 'INPUT' ||
    targetNodeName === 'TEXTAREA' ||
    target?.isContentEditable) as boolean
}

import { Engine } from '../../../core/engine/Engine.type'
import { Entity } from '../../../core/entity'
import { LayerMeta } from '../../../core/layer_meta'
import { RenderSpriteFunction } from '../../../core/render_sprite'
import { RoadKind } from '../../../core/road_kind.enum'
import deleteOnHotModuleReload from '../../../hmr/delete_on_hot_module_reload'
import {
  Road_Dots_path418_d,
  Road_Dots_path418_fill,
  Road_Dots_path419_d,
  Road_Dots_path419_fill,
  Road_Dots_path420_d,
  Road_Dots_path420_fill,
  Road_Dots_path422_d,
  Road_Dots_path422_fill,
  Road_Dots_path423_d,
  Road_Dots_path423_fill,
  Road_Dots_path424_d,
  Road_Dots_path424_fill,
  Road_Dots_path518_d,
  Road_Dots_path518_fill,
  Road_Dots_path519_d,
  Road_Dots_path519_fill,
  Road_Dots_path520_d,
  Road_Dots_path520_fill,
  Road_Dots_path522_d,
  Road_Dots_path522_fill,
  Road_Dots_path523_d,
  Road_Dots_path523_fill,
  Road_Dots_path524_d,
  Road_Dots_path524_fill,
  Road_Group1_GreenEdge1_d,
  Road_Group1_GreenEdge1_fill,
  Road_Group1_GreyBody1_d,
  Road_Group1_GreyBody1_fill,
  Road_Group1_GreyLine1_d,
  Road_Group1_GreyLine1_fill,
  Road_Group1_WhiteLine1_d,
  Road_Group1_WhiteLine1_fill,
  Road_height,
  Road_WhiteDottedLine1_d,
  Road_WhiteDottedLine1_fill,
  Road_width,
} from '../../../svg/sprites_data'
import { createOffscreenCanvas } from '../../create_canvas'
import { EitherRenderingContext2D, getCanvas2dContext } from '../../get_canvas_2d_context'

const PATH_SIZE = 32

const elem = createOffscreenCanvas(Road_width, Road_height)

// const scale_factor = BASE_TILE_SIZE / Road_width

const ctx = getCanvas2dContext(elem)
if (ctx) {
  // ctx.scale(scale_factor, scale_factor)
  ctx.save()

  ctx.fillStyle = Road_Group1_GreenEdge1_fill
  ctx.fill(Road_Group1_GreenEdge1_d)

  ctx.fillStyle = Road_Group1_GreyLine1_fill
  ctx.fill(Road_Group1_GreyLine1_d)

  ctx.fillStyle = Road_Group1_WhiteLine1_fill
  ctx.fill(Road_Group1_WhiteLine1_d)

  ctx.fillStyle = Road_Group1_GreyBody1_fill
  ctx.fill(Road_Group1_GreyBody1_d)

  ctx.fillStyle = Road_WhiteDottedLine1_fill
  ctx.fill(Road_WhiteDottedLine1_d)

  ctx.fillStyle = Road_Dots_path418_fill
  ctx.fill(Road_Dots_path418_d)
  ctx.fillStyle = Road_Dots_path419_fill
  ctx.fill(Road_Dots_path419_d)
  ctx.fillStyle = Road_Dots_path420_fill
  ctx.fill(Road_Dots_path420_d)
  ctx.fillStyle = Road_Dots_path422_fill
  ctx.fill(Road_Dots_path422_d)
  ctx.fillStyle = Road_Dots_path423_fill
  ctx.fill(Road_Dots_path423_d)
  ctx.fillStyle = Road_Dots_path424_fill
  ctx.fill(Road_Dots_path424_d)
  ctx.fillStyle = Road_Dots_path518_fill
  ctx.fill(Road_Dots_path518_d)
  ctx.fillStyle = Road_Dots_path519_fill
  ctx.fill(Road_Dots_path519_d)
  ctx.fillStyle = Road_Dots_path520_fill
  ctx.fill(Road_Dots_path520_d)
  ctx.fillStyle = Road_Dots_path522_fill
  ctx.fill(Road_Dots_path522_d)
  ctx.fillStyle = Road_Dots_path523_fill
  ctx.fill(Road_Dots_path523_d)
  ctx.fillStyle = Road_Dots_path524_fill
  ctx.fill(Road_Dots_path524_d)

  // ctx.fillStyle = 'pink'
  // ctx.fillRect(0, 0, PATH_SIZE, PATH_SIZE)

  // create bottom of left-right
  {
    const sx = 1 * PATH_SIZE
    const sy = 2.5 * PATH_SIZE
    const dx = 0 * PATH_SIZE
    const dy = 3.5 * PATH_SIZE
    ctx.drawImage(elem, sx, sy, PATH_SIZE, PATH_SIZE / 2, dx, dy, PATH_SIZE, PATH_SIZE / 2)
  }
  // create top of left-right
  {
    const sx = 1 * PATH_SIZE
    const sy = 0
    const dx = 0
    const dy = 3 * PATH_SIZE
    ctx.drawImage(elem, sx, sy, PATH_SIZE, PATH_SIZE / 2, dx, dy, PATH_SIZE, PATH_SIZE / 2)
  }
  // create left of up-down
  {
    const sx = 0 * PATH_SIZE
    const sy = 1 * PATH_SIZE
    const dx = 1 * PATH_SIZE
    const dy = 3 * PATH_SIZE
    ctx.drawImage(elem, sx, sy, PATH_SIZE / 2, PATH_SIZE, dx, dy, PATH_SIZE / 2, PATH_SIZE)
  }
  // create right of up-down
  {
    const sx = 2.5 * PATH_SIZE
    const sy = 1 * PATH_SIZE
    const dx = 1.5 * PATH_SIZE
    const dy = 3 * PATH_SIZE
    ctx.drawImage(elem, sx, sy, PATH_SIZE / 2, PATH_SIZE, dx, dy, PATH_SIZE / 2, PATH_SIZE)
  }
  ctx.restore()
}

type RoadVariantOffset = { road: RoadKind; sx: number; sy: number }
const variantOffsets: Readonly<Array<RoadVariantOffset>> = [
  {
    // four way
    road: RoadKind.Up | RoadKind.Down | RoadKind.Left | RoadKind.Right,
    sx: 1 * PATH_SIZE,
    sy: 1 * PATH_SIZE,
  },
  {
    // three connections
    road: RoadKind.Down | RoadKind.Left | RoadKind.Right,
    sx: 1 * PATH_SIZE,
    sy: 0 * PATH_SIZE,
  },
  {
    road: RoadKind.Up | RoadKind.Left | RoadKind.Right,
    sx: 1 * PATH_SIZE,
    sy: 2 * PATH_SIZE,
  },
  {
    road: RoadKind.Up | RoadKind.Down | RoadKind.Right,
    sx: 0 * PATH_SIZE,
    sy: 1 * PATH_SIZE,
  },
  {
    road: RoadKind.Up | RoadKind.Down | RoadKind.Left,
    sx: 2 * PATH_SIZE,
    sy: 1 * PATH_SIZE,
  },
  {
    // turns
    road: RoadKind.Down | RoadKind.Right,
    sx: 0 * PATH_SIZE,
    sy: 0 * PATH_SIZE,
  },
  {
    road: RoadKind.Down | RoadKind.Left,
    sx: 2 * PATH_SIZE,
    sy: 0 * PATH_SIZE,
  },
  {
    road: RoadKind.Up | RoadKind.Right,
    sx: 0 * PATH_SIZE,
    sy: 2 * PATH_SIZE,
  },
  {
    road: RoadKind.Up | RoadKind.Left,
    sx: 2 * PATH_SIZE,
    sy: 2 * PATH_SIZE,
  },
  {
    // straight
    road: RoadKind.Right | RoadKind.Left,
    sx: 0 * PATH_SIZE,
    sy: 3 * PATH_SIZE,
  },
  {
    road: RoadKind.Up | RoadKind.Down,
    sx: 1 * PATH_SIZE,
    sy: 3 * PATH_SIZE,
  },
]

const renderFunctionCache = new WeakMap<RoadVariantOffset, RenderSpriteFunction>()

export default function makeRenderRoad(engine: Engine, entity: Entity): RenderSpriteFunction {
  const variant: RoadKind = (entity as { road: RoadKind }).road

  for (let index = 0; index < variantOffsets.length; index++) {
    const vo = variantOffsets[index]
    if ((variant & vo.road) === vo.road) {
      let fn = renderFunctionCache.get(vo)
      if (!fn) {
        const sx = vo.sx
        const sy = vo.sy
        fn = function renderRoad(
          ctx: EitherRenderingContext2D,
          lm: LayerMeta,
          entity: Entity,
          dx: number,
          dy: number,
          tile_size: number
        ): void {
          ctx.drawImage(elem, sx, sy, PATH_SIZE, PATH_SIZE, dx, dy, tile_size, tile_size)
        }
        renderFunctionCache.set(vo, fn)
        deleteOnHotModuleReload(renderFunctionCache, vo)
      }
      return fn
    }
  }

  // TODO maybe render a a big X
  throw new Error('unknown road')
}

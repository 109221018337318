import { Accessor, createMemo } from 'solid-js'
import { Nullable } from '../../../typescript'
import { Engine } from '../engine/Engine.type'
import { Player } from './Player.type'

export default function createSelectedPlayerMemo(engine: Engine): Accessor<Nullable<Player>> {
  const selectedPlayer = createMemo<Nullable<Player>>(() => {
    const players = engine.state.players
    const playerId = engine.selectedPlayerId

    const player = playerId ? players.find((p) => p.id === playerId) : null
    // console.log('SelectPlayerInput.memo.selectedPlayer', playerId, player?.dname || null)
    return player
  })
  return selectedPlayer
}

import { addBreadcrumb, captureException } from '@sentry/browser'
import { isServer } from 'solid-js/web'
import { flatten, isValiError, parse } from 'valibot'
import { setAlertBagError } from '../../../../rx/store/create_alert_bag_store'
import { Engine } from '../../engine/Engine.type'
import Action from './action/Action.type'
import actionHandlersList, { type ActionSchemaUnion } from './actionHandlersList.generated'
import postRemoteAction from './postRemoteAction'

// let lastClientDispatch: Promise<unknown> | null = null
export default async function dispatchClient(engine: Engine, action: Action): Promise<void> {
  addBreadcrumb({
    category: 'action',
    level: 'info',
    data: {
      action,
    },
  })
  if (isServer) {
    throw new Error('dispatchClient is only available on the client')
  }
  try {
    // const handler = actionHandlersList.get(action.type)
    const tuple = actionHandlersList.find((t) => action.type === t[0])
    if (tuple) {
      // const actionType = tuple[0]
      const actionSchema: ActionSchemaUnion = tuple[1]
      // const actionHandler: ActionHandler = tuple[2]
      const validatedAction = parse(actionSchema, action)
      // await actionHandler(engine, validatedAction)

      let p: Promise<unknown> | null = null
      if (engine.state.online?.id) {
        p = postRemoteAction(engine, validatedAction, engine.state.online?.id)
      }
      // await createDefaultWait(1)
      // no
      // if (shouldOptimisticUpdate) {
      // modifyMutable(
      //   engine,
      //   produce(async () => {
      //     await actionHandler(engine, validatedAction)
      //   })
      // )
      // }
      // throw an exception if an action fails the schema
      // parse(StateSchema, engine.state)

      await p

      // modifyMutable(
      //   engine,
      //   produce(async () => {
      //     engine.prevState = deepClone(engine.state)
      //   })
      // )
    } else {
      throw new Error(`Action ${action.type} is not supported`)
    }
  } catch (err) {
    const { toast, ab } = engine
    const msg: string = (err as { message?: string }).message || 'unknown'
    const context: Record<string, unknown> = {}
    if (isValiError(err)) {
      context.errors = flatten(err.issues)
    }
    const err2 = new Error(`Action Failed: ${msg}`, { cause: err })
    console.error(err, context)
    captureException(err, context)
    if (toast) {
      toast.error(msg)
    }
    if (ab) {
      setAlertBagError(ab, err2)
    }
  }
}

import { WeaponTypeId } from './WeaponTypeId.enum'
import type { WeaponTypeMeta } from './WeaponTypeMeta.type'
import { weaponTypeMetaList } from './weaponTypeMetaList'

export default function getWeaponTypeMeta(wt_id: WeaponTypeId): WeaponTypeMeta {
  const wtm = weaponTypeMetaList.find((wtm) => wtm.id === wt_id)
  if (wtm) {
    return wtm
  }
  throw new Error('unexpected !weaponTypeMeta')
}

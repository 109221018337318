import type { HasTaxiID } from '../entity/HasTaxiID'
import type { Entity } from '../entity/index'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'

type BlocedValueOrNull = boolean

export default function createLazyBlockedValueGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to move
  unit: Entity
) {
  const lazyCostGrid = createLazyReadOnlyGridXY<BlocedValueOrNull>(
    gridWidth,
    gridHeight,
    function costGridGetter(x: number, y: number): boolean {
      const positionStack = grid?.[x]?.[y]
      if (positionStack) {
        for (let index = 0; index < positionStack.length; index++) {
          const entity = positionStack[index];
          if (entity.id !== unit.id) {
            if ((entity as HasTaxiID).taxi_id) {
              // does not block, transport probably will block
            } else {
              return entity.layer_id === unit.layer_id
            }
          }
        }
      }
      // for now, a void tile is blocking
      return true
    }
  )
  return lazyCostGrid
}

import { Entity } from '../../core/entity/index'
import type { HasHP } from '../../core/has_hp'
import { LayerMeta } from '../../core/layer_meta'
import { floor, max } from '../../core/util/math'
import { createOffscreenCanvas } from '../create_canvas'
import { EitherRenderingContext2D, getCanvas2dContext } from '../get_canvas_2d_context'

export default function defaultErrorRender(
  ctx: EitherRenderingContext2D,
  lm: LayerMeta,
  entity: Entity,
  dx: number,
  dy: number,
  d_tile_size: number
) {
  const halfTileSize = floor(d_tile_size / 2)
  // console.log('halfTileSize', halfTileSize)
  if (halfTileSize > 8) {
    const hp = max(1, floor((entity as HasHP).hp))
    const buffer = getOrMakeFrame(hp, halfTileSize)
    // console.log('hp', hp)
    // ctx.globalAlpha = 0.25
    // ctx.strokeStyle = 'green'
    // ctx.fillRect(dx, dy, d_tile_size, d_tile_size)
    // ctx.globalAlpha = 0.5
    // ctx.strokeStyle = 'red'
    // ctx.lineWidth = 4
    // ctx.strokeRect(dx, dy, d_tile_size, d_tile_size)
    // ctx.moveTo(dx, dy)
    // ctx.lineTo(dx + d_tile_size, dy + d_tile_size)
    // ctx.moveTo(dx + d_tile_size, dy)
    // ctx.lineTo(dx, dy + d_tile_size)
    // ctx.stroke()
    // ctx.globalAlpha = 1
    ctx.drawImage(buffer, dx + halfTileSize, dy+halfTileSize)
  }
}

// const cache = new Map<number, OffscreenCanvas>()
const cache : Array<OffscreenCanvas> = []

function getOrMakeFrame (hp: number, halfTileSize: number): OffscreenCanvas {
  const elem = cache[hp] ||= createOffscreenCanvas(1, 1)
  if (elem.width !== halfTileSize) {
    elem.width = halfTileSize
    elem.height = halfTileSize
    const ctx = getCanvas2dContext(elem)
    if (ctx) {
      let count : number
      // ctx.fillStyle = 'black'
      // ctx.fillRect(0, 0, halfTileSize, halfTileSize)
      // ctx.fillStyle = 'white'
      // ctx.fillRect(1, 1, halfTileSize - 2, halfTileSize - 2)
      // ctx.font = `${halfTileSize}px monospace`
      ctx.font = `bold ${halfTileSize}px 'Courier New', Courier, 'Lucida Console', Monaco, 'DejaVu Sans Mono', 'Consolas', monospace`
      // ctx.font = `${halfTileSize}px sans-serif`
      // ctx.textBaseline = 'top'
      // ctx.textBaseline = 'alphabetic'
      ctx.textBaseline = 'middle'
      // ctx.textBaseline = 'bottom'
      // ctx.textAlign = 'right'
      ctx.textAlign = 'center'
      // ctx.textAlign = 'left'
      ctx.fillStyle = 'white'
      const textX = floor(halfTileSize / 2)
      const textY = floor(halfTileSize * (5/8))
      // const textY = floor(halfTileSize / 2)
      // ctx.filter = 'drop-shadow(2px 2px 2px black)'
      // ctx.fillStyle = 'black'
      // ctx.filter = 'blur(2px)'
      // ctx.fillText(`${hp}`, textX, textY, halfTileSize)
      ctx.fillStyle = 'black'
      ctx.filter = 'blur(1px)'
      count = 10
      while (count --> 0) {
        ctx.fillText(`${hp}`, textX, textY, halfTileSize)  
      }
      // ctx.fillStyle = 'grey'
      // ctx.filter = 'blur(1px)'
      // ctx.fillText(`${hp}`, textX, textY, halfTileSize)
      ctx.fillStyle = 'white'
      ctx.filter = 'none'
      ctx.fillText(`${hp}`, textX, textY, halfTileSize)
      // ctx.fillText(`${hp}`, halfTileSize / 2, 1 + (halfTileSize / 2))

      // if (hp > 7) {
      //   ctx.strokeStyle = '#030' // green
      // } else if (hp > 4) {
      //   ctx.strokeStyle = '#330' // yellow
      // } else {
      //   ctx.strokeStyle = '#A00' // red
      //   ctx.strokeStyle = '#330' // yellow
      // }
      ctx.strokeStyle = 'grey'
      // ctx.filter = 'none'
      count = 1
      while (count --> 0) {
        ctx.strokeText(`${hp}`, textX, textY, halfTileSize)
      }
    }
    // consoleImage(elem, 1)
  }
  return elem
}
import { Key } from '@solid-primitives/keyed'
import { FaSolidArrowRight, FaSolidX } from 'solid-icons/fa'
import { Component, createMemo, createSignal, on, Show, useContext, type Accessor, type JSX } from 'solid-js'
import { modifyMutable, produce } from 'solid-js/store'
import calculateDraftMoveAttackEstimates from '../../lib/core/draft_move/calculateDraftMoveAttackEstimates'
import type { DraftMove } from '../../lib/core/draft_move/DraftMove.type'
import type { Engine } from '../../lib/core/engine/Engine.type'
import resetEngineToDefaultSelectedTool from '../../lib/core/engine/resetEngineToDefaultSelectedTool'
import canUnitAnnexEntity from '../../lib/core/entity/canUnitAnnexEntity'
import getEntityTypeMetaById from '../../lib/core/entity/getEntityTypeMetaById'
import getTransportableDestPositionEntity from '../../lib/core/entity/getTransportableDestPositionEntity'
import hasAtLeastOneWeapon from '../../lib/core/entity/hasAtLeastOneWeapon'
import type { HasTaxiID } from '../../lib/core/entity/HasTaxiID'
import type { Entity } from '../../lib/core/entity/index'
import type { HasHP } from '../../lib/core/has_hp'
import type { HasAnnexPoints } from '../../lib/core/HasAnnexPoints.type'
import { SelectedToolId } from '../../lib/core/map_editor/SelectedToolId.enum'
import type { HasPlayerId } from '../../lib/core/player/has_player_id'
import { createMoveUnitAction } from '../../lib/core/state/flux/action/Game/MoveUnitAction'
import dispatchClient from '../../lib/core/state/flux/dispatchClient'
import coord from '../../lib/core/tile_position_xy/coord'
import isOrthogonallyAdjacent from '../../lib/core/tile_position_xy/isOrthogonallyAdjacent'
import { samePosition } from '../../lib/core/tile_position_xy/samePosition'
import Button from '../../lib/jsx/Button'
import withBusy from '../../lib/withBusy'
import type { Nullable } from '../../typescript'
import EngineContext from '../EngineContext'
import LoadingIcon from '../LoadingIcon'

const DraftMoveMenuWidget: Component = () => {
  const engine : Engine = useContext(EngineContext)

  const yourTurn = (): boolean => (engine.authPlayerId && engine.authPlayerId === engine.state.turnPlayerId) as boolean

  return (
    <Show when={yourTurn() && engine.draftMove} children={(draftMove) => {

      const [isBusy, setIsBusy] = createSignal<boolean>(false)

      const annexableEntity = createMemo<Nullable<Entity & HasAnnexPoints>>(() => {
        const { destPosition, startPosition, unit } = draftMove()
        const annexPosition = destPosition || startPosition
        if (annexPosition) {
          return engine.state.ents.find((entity : Entity): entity is Entity & HasAnnexPoints => {
            if (samePosition(entity, annexPosition)) {
              return canUnitAnnexEntity(unit, entity)
            }
            return false
          })
        }
      })
      // createEffect(() => {
      //   console.log('annexableEntity', unwrap(annexableEntity()), 'draftMove', unwrap(draftMove()))
      // })

      const directAttackableEntityList = createMemo<Array<Entity & HasHP>>(() => {
        const { destPosition, unit } = draftMove()
        if (destPosition && hasAtLeastOneWeapon(unit)) {
          const { player_id: unitPlayerId } = unit as Entity & HasPlayerId
          return engine.state.ents.filter((entity : Entity): entity is Entity & HasHP => {
            if ((entity as HasTaxiID).taxi_id) {
              return false
            }
            if (isOrthogonallyAdjacent(entity, destPosition)) {
              if (unitPlayerId && unitPlayerId !== (entity as HasPlayerId).player_id) {
                if ('hp' in entity) {
                  return true
                }
              }
            }
            return false
          })
        }
        return []
      })

      const transportableDestPositionEntity = createMemo<Nullable<Entity>>(on(() => engine.draftMove?.destPosition, () => {
        return getTransportableDestPositionEntity(engine)
      }))

      const cssStyle = createMemo<Partial<JSX.CSSProperties>>(() => {
        return {
          position: 'fixed',
          left: '10px',
          top: '10px',
        }
      })
      return (
        <div class="cw cw-dm float-end m-2" style={cssStyle()}>
          <Key each={directAttackableEntityList()} by="id"
            children={(directAttackableEntity) => {
              return <Button
                disabled={isBusy()}
                name="direct_attack"
                class="btn btn-primary d-block"
                ref={(elem : HTMLButtonElement) => {
                  // onmouseenter/onmouseleave does not include children
                  // onmouseover/onmouseout includes children
                  elem.onmouseover = () => {
                    const targetEnt = directAttackableEntity()
                    const position = coord(targetEnt.x, targetEnt.y)
                    modifyMutable(engine, produce((engine) => {
                      const { draftMove } = engine
                      if (draftMove && !samePosition(draftMove.attackPosition, position)) {
                        draftMove.attackPosition = position
                        calculateDraftMoveAttackEstimates(engine)
                      }
                    }))
                  }
                  elem.onmouseout = () => {
                    const targetEnt = directAttackableEntity()
                    const position = coord(targetEnt.x, targetEnt.y)
                    modifyMutable(engine, produce((engine) => {
                      const { draftMove } = engine
                      if (draftMove && samePosition(draftMove.attackPosition, position)) {
                        draftMove.attackPosition = null
                        calculateDraftMoveAttackEstimates(engine)
                      }
                    }))
                  }
                }}
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  const targetEnt = directAttackableEntity()
                  const newDraftMove : DraftMove = {
                    ...draftMove(),
                    attackPosition: coord(targetEnt.x, targetEnt.y),
                    target: targetEnt,
                  }
                  engine.selectedTool = SelectedToolId.MoveUnit
                  engine.draftMove = newDraftMove
                  calculateDraftMoveAttackEstimates(engine)
                  await dispatchClient(engine, createMoveUnitAction(newDraftMove))
                  modifyMutable(engine, produce((engine) => {
                    resetEngineToDefaultSelectedTool(engine)
                  }))
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight/>} />
                {` Attack ${getEntityTypeMetaById(directAttackableEntity().etype_id)?.dname} `}
                <Show when={directAttackableEntityList().length === 1}
                  children={<span class="text-muted small">[d]</span>}
                />
              </Button>
            }}
          />
          <Show when={annexableEntity()}
            children={(annexableEntity) => {
              // const annexableEntityTypeMeta = () => 
              return <Button
                disabled={isBusy()}
                name="annex"
                class="btn btn-primary d-block"
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  const newDraftMove : DraftMove = {
                    ...draftMove(),
                    annex: true,
                  }
                  engine.selectedTool = SelectedToolId.MoveUnit
                  // console.log('engine.draftMove = newDraftMove')
                  engine.draftMove = newDraftMove
                  calculateDraftMoveAttackEstimates(engine)
                  await dispatchClient(engine, createMoveUnitAction(newDraftMove))
                  modifyMutable(engine, produce((engine) => {
                    resetEngineToDefaultSelectedTool(engine)
                  }))
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight/>} />
                {` Annex ${getEntityTypeMetaById(annexableEntity().etype_id)?.dname} `}
                <span class="text-muted small">[a]</span>
              </Button>
            }}
          />
          <Show when={transportableDestPositionEntity()} children={(cargoEnt : Accessor<Entity>) => {
            return (
              <Button
                disabled={isBusy()}
                name="pickup"
                class="btn btn-primary d-block"
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  await dispatchClient(engine, createMoveUnitAction({
                    ...draftMove(),
                    pickup: cargoEnt(),
                  }))
                  modifyMutable(engine, produce((engine) => {
                    resetEngineToDefaultSelectedTool(engine)
                  }))
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight/>} />
                {' Pickup '}
                <span class="text-muted small">[p]</span>
              </Button>
            )
          }} />
          <Show when={draftMove().destPosition}>
            <Button
              disabled={isBusy()}
              name="commit"
              class="btn btn-primary d-block"
              onClick={withBusy(isBusy, setIsBusy, async () => {
                await dispatchClient(engine, createMoveUnitAction(draftMove()))
                modifyMutable(engine, produce((engine) => {
                  resetEngineToDefaultSelectedTool(engine)
                }))
              })}
            >
              <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight/>} />
              {' Commit '}
              <span class="text-muted small">[w]</span>
            </Button>
          </Show>
          <Show when={draftMove() && !draftMove().destPosition}>
            <Button
              disabled={isBusy()}
              name="wait"
              class="btn btn-primary d-block"
              onClick={withBusy(isBusy, setIsBusy, async () => {
                await dispatchClient(engine, createMoveUnitAction({
                  ...draftMove(),
                  wait: true,
                }))
                modifyMutable(engine, produce((engine) => {
                  resetEngineToDefaultSelectedTool(engine)
                }))
              })}
            >
              <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidX/>} />
              {' Wait '}
              <span class="text-muted small">[w]</span>
            </Button>
            <Button
              disabled={isBusy()}
              name="cancel"
              class="btn btn-primary d-block"
              onClick={withBusy(isBusy, setIsBusy, async () => {
                modifyMutable(engine, produce((engine) => {
                  resetEngineToDefaultSelectedTool(engine)
                }))
              })}
            >
              <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidX/>} />
              {' Cancel '}
              <span class="text-muted small">[esc]</span>
            </Button>
          </Show>
        </div>
      )
    }} />
  )
}

export default DraftMoveMenuWidget

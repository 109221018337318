import { literal, nullable, object, optional, pipe, rawCheck, type InferOutput } from 'valibot'
import Unexpected from '../../../../../Exception/Unexpected.class'
import isNil from '../../../../../ldsh/isNil'
import type { HasAttackedThisTurn } from '../../../../AttackedThisTurn'
import createDraftMoveAttackEstimate from '../../../../draft_move/createDraftMoveAttackEstimate'
import type { DraftMove } from '../../../../draft_move/DraftMove.type'
import { Engine } from '../../../../engine/Engine.type'
import resetEngineToDefaultSelectedTool from '../../../../engine/resetEngineToDefaultSelectedTool'
import type { BaseEntity } from '../../../../entity/BaseEntity'
import canUnitAnnexEntity from '../../../../entity/canUnitAnnexEntity'
import getEntityTypeMetaById from '../../../../entity/getEntityTypeMetaById'
import type { HasTaxiID } from '../../../../entity/HasTaxiID'
import { EntityIdSchema } from '../../../../entity/id.type'
import type { Entity } from '../../../../entity/index'
import type { HasHP } from '../../../../has_hp'
import type { HasMobility } from '../../../../has_mobility'
import type { HasAnnexPoints } from '../../../../HasAnnexPoints.type'
import type { HasPlayerId } from '../../../../player/has_player_id'
import entityListToGridXY from '../../../../tile_position_xy/entityListToGridXY'
import findMostEfficientPathToTileV2 from '../../../../tile_position_xy/findMostEfficientPathToTileV2'
import isOrthogonallyAdjacent from '../../../../tile_position_xy/isOrthogonallyAdjacent'
import { samePosition } from '../../../../tile_position_xy/samePosition'
import { TilePositionXYSchema } from '../../../../tile_position_xy/TilePositionXY.type'
import toCoord from '../../../../tile_position_xy/toCoord'
import { max } from '../../../../util/math'
import type { HasWaitedThisTurn } from '../../../../WaitedThisTurn'
import { ActionType } from '../ActionType'

export const MoveUnitActionSchema = pipe(object({
  type: literal(ActionType.Game.MoveUnit),
  unit_id: EntityIdSchema,
  target_id: nullable(EntityIdSchema),
  pickup_id: optional(nullable(EntityIdSchema)),
  start_position: TilePositionXYSchema,
  dest_position: nullable(TilePositionXYSchema),
  attack_position: nullable(TilePositionXYSchema),
  annex: nullable(literal(true)),
  wait: optional(literal(true)),
}), rawCheck(({ dataset, addIssue }) => {
  if (dataset.typed) {
    console.log('dataset.value', dataset.value)
    const {
      target_id,
      dest_position,
      attack_position,
      annex,
      wait,
      pickup_id,
    } = dataset.value
    if (!(target_id||dest_position||attack_position||annex||wait||pickup_id)) {
      addIssue({
        message: 'Blank Move is Invalid',
      })
    }
    if (attack_position && !target_id) {
      addIssue({
        message: 'attack_position requires target_id',
      })
    }
    if (pickup_id && (attack_position || annex)) {
      addIssue({
        message: 'cannot combine pickup and attack',
      })
    }
  }
}))

export type MoveUnitAction = InferOutput<typeof MoveUnitActionSchema>

export function createMoveUnitAction(draftMove: DraftMove): MoveUnitAction {
  return {
    type: ActionType.Game.MoveUnit,
    unit_id: draftMove.unit.id,
    target_id: draftMove.target?.id || null,
    pickup_id: draftMove.pickup?.id,
    start_position: draftMove.startPosition,
    dest_position: draftMove.destPosition,
    attack_position: draftMove.attackPosition,
    annex: draftMove.annex,
    wait: draftMove.wait,
  }
}

export async function handleMoveUnitAction(engine: Engine, action: MoveUnitAction): Promise<void> {
  // console.log('handleMoveUnitAction', deepClone(action))
  const {
    annex,
    unit_id,
    target_id,
    pickup_id,
    start_position,
    dest_position,
    attack_position,
    wait,
  } = action
  const { state } = engine
  const { ents, width, height } = state
  const unit = ents.find((ent: Entity) => ent.id === unit_id)
  if (!unit) {
    throw new Error('unexpected !unit')
  }
  if (!samePosition(unit, start_position)) {
    throw new Error('unexpected !samePosition(unit, start_position)')
  }
  const unitEntityTypeMeta = getEntityTypeMetaById(unit.etype_id)
  const { mobility: unitDefaultMobility } = unitEntityTypeMeta.entDefaults as HasMobility
  const entityGridXY = entityListToGridXY(ents, width, height)
  const unitPosition = dest_position || toCoord(unit)

  if (annex && attack_position) {
    throw new Error('unexpected annex && attack_position')
  }

  if (dest_position) {
    // console.log('the unit is going somewhere')
    if (samePosition(unit, dest_position)) {
      throw new Error('unexpected samePosition(unit, dest_position)')
    }
    if (!('mobility' in unit)) {
      throw new Error('Unit cant move')
    }
    const unitPathTurnSteps = findMostEfficientPathToTileV2(
      entityGridXY,
      unit,
      width,
      height,
      dest_position.x,
      dest_position.y
    )
    if (!unitPathTurnSteps) {
      throw new Error('No Valid Path')
    }
    // console.log('unitPathTurnSteps', unitPathTurnSteps)
    const turn = unitPathTurnSteps.turns[0]
    if (!turn) {
      throw new Error('No Valid Path')
    }
    const stepsMobilityCost = turn.steps.reduce((sum: number, step) => sum + (step.cost || 0), 0)
    if (!(unit.mobility > 0)) {
      throw new Error('Unit out of movies')
    }
    const lastStep = turn.steps[turn.steps.length - 1]
    if (!lastStep) {
      throw new Error('No Valid Path')
    }
    unit.x = lastStep.x
    unit.y = lastStep.y

    unit.mobility = max(0, unit.mobility - stepsMobilityCost)
    unit.fuel = max(0, unit.fuel - stepsMobilityCost)

    const { cargo } = (unit as BaseEntity)
    if (cargo) {
      for (let index = 0; index < ents.length; index++) {
        const ent2 = ents[index];
        if ((ent2 as HasTaxiID).taxi_id === unit.id) {
          ent2.x = unit.x
          ent2.y = unit.y
        }
      }
    }
  }

  if (annex) {
    const annexPosition = dest_position || start_position
    // console.log('the unit is annexing something')

    if (annexPosition) {
      const annexableEnt = engine.state.ents.find(
        (entity: Entity): entity is Entity & HasAnnexPoints => {
          if (samePosition(entity, annexPosition)) {
            return canUnitAnnexEntity(unit, entity)
          }
          return false
        }
      )
      if (!annexableEnt) {
        throw new Error('unexpected !annexableEnt')
      }
      const annexDelta = (unit as HasHP).hp
      if (!annexDelta) {
        throw new Error('unexpected !annexDelta')
      }
      const annexableEntityTypeMeta = getEntityTypeMetaById(annexableEnt.etype_id)
      const defaultAnnexPoints = (annexableEntityTypeMeta.entDefaults as HasAnnexPoints).ap

      // TODO also cleanup orphaned annexations
      if (annexableEnt.ap_ent_id !== unit.id) {
        annexableEnt.ap = defaultAnnexPoints
        annexableEnt.ap_ent_id = unit.id
      }
      annexableEnt.ap -= annexDelta
      if (annexableEnt.ap <= 0) {
        ;(annexableEnt as HasPlayerId).player_id = (unit as HasPlayerId).player_id
        annexableEnt.ap = defaultAnnexPoints
        annexableEnt.ap_ent_id = null
      }
      ;(unit as HasMobility).mobility = 0
    }
  }

  if (attack_position) {
    if (samePosition(unit, attack_position)) {
      throw new Error('unexpected samePosition(unit, attack_position)')
    }
    if (!target_id) {
      throw new Error('unexpected !target_id')
    }
    const target = ents.find((ent: Entity) => ent.id === target_id)
    if (!target) {
      throw new Error('unexpected !target')
    }
    if ((target as HasTaxiID).taxi_id) {
      throw new Unexpected('target.taxi_id')
    }
    if (!samePosition(target, attack_position)) {
      throw new Error('unexpected !samePosition(target, attack_position)')
    }

    const isRangedAttack = !isOrthogonallyAdjacent(attack_position, dest_position || start_position)

    if (isRangedAttack) {
      const unitHasFullMobilityPoints = unitDefaultMobility && unitDefaultMobility === (unit as HasMobility).mobility
      if (!unitHasFullMobilityPoints) {
        throw new Error('unexpected unitDefaultMobility!==unit.mobility')
      }
    }

    const weaponEstimate = createDraftMoveAttackEstimate(engine, unit, unitPosition, target)
    // if (!weaponEstimate) {
    //   throw new Error('unexpected !weaponEstimate')
    // }

    const { unitWeaponEstimate, targetWeaponEstimate } = weaponEstimate
    if (!unitWeaponEstimate) {
      throw new Error ('unexpected !unitWeaponEstimate')
    }
    const { weapon: unitWeapon } = unitWeaponEstimate
    // the initial attack
    if (!isNil(weaponEstimate.targetDmg)) {
      if (unitWeapon.ammo) {
        unitWeapon.ammo--
      }
      ;(target as HasHP).hp -= weaponEstimate.targetDmg
    } else {
      throw new Error('unexpected !(unitWeapon && !isNil(weaponEstimate.targetDmg))')
    }

    // the counter attack
    const targetWeapon = targetWeaponEstimate?.weapon 
    if (targetWeapon && !isNil(weaponEstimate.unitDmg) && (target as HasHP).hp >= 0) {
      if (targetWeapon.ammo) {
        targetWeapon.ammo--
      }
      ;(unit as HasHP).hp -= weaponEstimate.unitDmg
    }

    if (!((target as HasHP).hp >= 0)) {
      state.ents = ents.filter(ent => {
        // the unit is dead
        if (ent.id == target_id) {
          return false
        }
        // the unit's cargo is destroyed
        if ((ent as HasTaxiID).taxi_id == target_id) {
          return false
        }
        return true
      })
    }

    ;(unit as HasMobility).mobility = 0
    ;(unit as HasAttackedThisTurn).attackedThisTurn = true
  }

  if (pickup_id) {
    const cargoEnt = ents.find(ent => ent.id === pickup_id)
    if (!cargoEnt) {
      throw new Unexpected('!cargoEnt')
    }
    if ((cargoEnt as HasTaxiID).taxi_id) {
      throw new Unexpected('cargoEnt already in taxi')
    }
    const { transports } = unitEntityTypeMeta
    if (!(transports && transports.includes(cargoEnt.etype_id))) {
      throw new Unexpected('!unit.canTransport(cargoEnt)')
    }
    const { cargo } = (unit as BaseEntity)
    if (!cargo) {
      throw new Unexpected('!cargo')
    }
    if (!(cargo.length < (unitEntityTypeMeta.cargoLimit || 0))) {
      throw new Error('transport full')
    }
    cargo.push(cargoEnt.id)
    ;(cargoEnt as HasTaxiID).taxi_id = unit.id
  }

  if (wait) {
    ;(unit as HasWaitedThisTurn).waitedThisTurn = true
  }

  resetEngineToDefaultSelectedTool(engine)
}

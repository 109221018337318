import type { Weapon } from './Weapon.type'
import { WeaponTypeId } from './WeaponTypeId.enum'

export default function createArtillaryCannonWeapon(str: number): Weapon {
  return {
    wt_id: WeaponTypeId.ArtillaryCannon,
    str,
    ammo: 1,
    minRange: 2,
    maxRange: 3,
  }
}

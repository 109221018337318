import { modifyMutable, produce } from 'solid-js/store'
import { Engine } from './engine/Engine.type'

export default function calculateMapWidthHeightPxTileSize(engine: Engine) {
  modifyMutable(
    engine,
    produce((engine: Engine) => {
      const { viewCtx, state } = engine
      const { width, height } = state
      const { tile_size, tile_gap_px } = viewCtx
      viewCtx.mapWidthPx = ((tile_size + tile_gap_px) * width) - tile_gap_px
      viewCtx.mapHeightPx = ((tile_size + tile_gap_px) * height) - tile_gap_px
    })
  )
}

import type { Nullable } from '../../../typescript'
import canUnitAttackEntity from '../entity/canUnitAttackEntity'
import getEntityTypeMetaById from '../entity/getEntityTypeMetaById'
import hasAtLeastOneWeapon from '../entity/hasAtLeastOneWeapon'
import type { Entity } from '../entity/index'
import type { HasHP } from '../has_hp'
import type { HasMobility } from '../has_mobility'
import type { HasPlayerId } from '../player/has_player_id'
import coord from './coord'
import createLazyBlockedValueGrid from './createLazyBlockedValueGrid'
import createLazyDefenseValueGrid from './createLazyDefenseValueGrid'
import createLazyMovementCostGrid from './createLazyMovementCostGrid'
import createReadWriteGridXY from './createReadWriteGridXY'
import { dijkstraDirections } from './dijkstraDirections'
import type { EntityGridXY } from './EntityGridXY.type'
import isOrthogonallyAdjacent from './isOrthogonallyAdjacent'
import type { TilePositionXY } from './TilePositionXY.type'

export type PathTurnSteps_Turn = {
  // number of points unit starts with in turn
  mobility: number
  steps: Array<PathTurnSteps_Step>
  turn: number
  attack?: true
}

export type PathTurnSteps_Step = {
  x: number
  y: number
  cost: number
  defense: number | null
  blocked: boolean
}

export type PathTurnSteps = {
  // total points cost of all steps combined
  totalCost: number
  // each step
  turns: Array<PathTurnSteps_Turn>
  start: TilePositionXY
  end: TilePositionXY
  attack?: true
  blocked: boolean
}

// at 100 turns, throw error
const maxTurns = 100

export default function findMostEfficientPathToTileV2(
  grid: EntityGridXY,
  unit: Entity,
  gridWidth: number,
  gridHeight: number,
  targetX: number,
  targetY: number
): PathTurnSteps | null {
  // console.log('findMostEfficientPathToTileV2.start', { targetX, targetY })

  const unitEntityTypeMeta = getEntityTypeMetaById(unit.etype_id)
  const movePointsPerTurn: number | undefined = (unitEntityTypeMeta.entDefaults as HasMobility).mobility
  const movePointsLeftInFirstTurn: number | undefined = (unit as HasMobility).mobility

  const unitHasWeapon = hasAtLeastOneWeapon(unit)

  if (!movePointsPerTurn) {
    // console.log('findMostEfficientPathToTileV2', '!movePointsPerTurn')
    return null
  }
  // const gridWidth = grid.length
  // const gridHeight = grid[0].length

  const unitPlayerId = (unit as HasPlayerId).player_id

  // start x and y
  const { x, y } = unit
  const unitPosition: TilePositionXY = coord(x, y)
  const targetPosition = coord(targetX, targetY)
  const attackableEntityAtTarget : Nullable<Entity & HasHP & HasPlayerId> = (unitPlayerId && unitHasWeapon) ? grid[targetX]?.[targetY]?.find((ent): ent is Entity & HasHP & HasPlayerId => {
    return unitPlayerId !== (ent as HasPlayerId).player_id && (ent as HasHP).hp > 0
  }) : null
  // if (targetX===6&&targetY===6) console.log('findMostEfficientPathToTileV2.attackableEntityAtTarget', deepClone(attackableEntityAtTarget))

  const costGrid = createLazyMovementCostGrid(gridWidth, gridHeight, grid, unit)
  const defenseAt = createLazyDefenseValueGrid(gridWidth, gridHeight, grid, unit)
  const blockedAt = createLazyBlockedValueGrid(gridWidth, gridHeight, grid, unit)
  const [getBest, setBest] = createReadWriteGridXY<PathTurnSteps | null>(
    gridWidth,
    gridHeight,
    null
  )
  const pathZero: PathTurnSteps = {
    totalCost: 0,
    start: unitPosition,
    end: unitPosition,
    turns: [],
    blocked: blockedAt(unitPosition.x, unitPosition.y),
  }
  setBest(unitPosition.x, unitPosition.y, pathZero)

  const queue: Array<PathTurnSteps> = [pathZero]

  let iterationsRemaining = 100 * gridWidth * gridHeight
  let previousPath: PathTurnSteps | undefined
  while ((previousPath = queue.shift())) {
    if (!(iterationsRemaining-- > 0)) {
      throw new Error('Max iterations exceeded')
    }

    // console.log('while(queue.shift) previousPath', previousPath)
    // const previousPath: Path = queue.shift()
    const {
      start: prevStart,
      end: prevEnd,
      turns: prevTurns,
      totalCost: prevTotalCost,
    } = previousPath
    for (const { dx, dy } of dijkstraDirections) {
      const nextX = prevEnd.x + dx
      const nextY = prevEnd.y + dy
      const nextPosition = coord(nextX, nextY)
      const inBounds = nextX >= 0 && nextY >= 0 && nextX < gridWidth && nextY < gridHeight
      // console.log({ nextX, nextY, inBounds })
      // console.log(
      //   'inBounds',
      //   `${nextX} >= 0 && ${nextY} >= 0 && ${nextX} < ${gridWidth} && ${nextY} < ${gridHeight}`
      // )
      if (inBounds) {
        const isOrthogonallyAdjacentToTarget = isOrthogonallyAdjacent(nextPosition, targetPosition)
        const stepCost = costGrid(nextX, nextY)

        const isLastStepAttack = isOrthogonallyAdjacentToTarget && stepCost===null
          && attackableEntityAtTarget && canUnitAttackEntity(unit, attackableEntityAtTarget)
        // const isLastStepAttack = isOrthogonallyAdjacentToTarget && stepCost===null && canUnitAttackEntity(unit, )

        // if (targetX===6&&targetY===6 && isOrthogonallyAdjacentToTarget && stepCost===null) {
        //   console.log(deepClone({
        //     isOrthogonallyAdjacentToTarget,
        //     stepCost,
        //     // 'stepCost===null': stepCost===null,
        //     attackableEntityAtTarget,
        //     'canUnitAttackEntity(unit, attackableEntityAtTarget)': attackableEntityAtTarget && canUnitAttackEntity(unit, attackableEntityAtTarget),
        //   }))
        //   console.log('isLastStepAttack', isLastStepAttack)
        // }

        // console.log({ nextX, nextY, stepCost })
        // if stepCost is null, the next tile is impassible
        if (isLastStepAttack) {
          const nextTotalCost = prevTotalCost
          const nextDefense = defenseAt(nextX, nextY)
          const currentBestPath = getBest(nextX, nextY)
          // console.log(currentBestPath?.start, 'lastStepDefense(currentBestPath)', lastStepDefense(currentBestPath), 'nextDefense', nextDefense, {nextX, nextY})
          // console.log({ nextTotalCost, currentBestTotalCost: currentBestPath?.totalCost })
          // make sure this newPath is actually good
          // before blowing out the gc
          const isNewBest = !(currentBestPath && (nextDefense as number) >= (lastStepDefense(currentBestPath) || 0))
          // console.log(
          //   JSON.stringify({
          //     isNewBest,
          //     hasCurrentBest: !!currentBestPath,
          //     'nextTotalCost >= currentBestPath.totalCost':
          //       nextTotalCost >= (currentBestPath?.totalCost || 0),
          //   })
          // )
          if (isNewBest) {
            const newPath: PathTurnSteps = {
              totalCost: nextTotalCost,
              start: prevStart,
              end: { x: nextX, y: nextY },
              turns: [...prevTurns],
              attack: true,
              blocked: blockedAt(nextX, nextY),
            }
            // console.log('newPath(setBest+queue.push)', JSON.stringify(newPath).replaceAll('"', ''))
            setBest(nextX, nextY, newPath)
            // attacks are terminating, so no additional queueing from this branch
          }
        } else if (stepCost) {
          const nextTotalCost = prevTotalCost + stepCost
          const currentBestPath = getBest(nextX, nextY)
          // console.log({ nextTotalCost, currentBestTotalCost: currentBestPath?.totalCost })
          // make sure this newPath is actually good
          // before blowing out the gc
          const isNewBest = !(currentBestPath && nextTotalCost >= currentBestPath.totalCost)
          // console.log(
          //   JSON.stringify({
          //     isNewBest,
          //     hasCurrentBest: !!currentBestPath,
          //     'nextTotalCost >= currentBestPath.totalCost':
          //       nextTotalCost >= (currentBestPath?.totalCost || 0),
          //   })
          // )
          if (isNewBest) {
            const lastTurnIndex = prevTurns.length - 1
            const prevLastTurn = prevTurns[lastTurnIndex]
            let turns: Array<PathTurnSteps_Turn>
            const newStep : PathTurnSteps_Step = {
              x: nextX,
              y: nextY,
              cost: stepCost,
              defense: defenseAt(nextX, nextY),
              blocked: blockedAt(nextX, nextY),
            }
            if (prevLastTurn) {
              // console.log('branch: prevLastTurn')
              const turnCost = prevLastTurn.steps.reduce(sumCost, 0)
              if (turnCost >= prevLastTurn.mobility) {
                // console.log('branch: prevLastTurn: turnCost >= movePointsPerTurn')
                const newTurn = {
                  steps: [newStep],
                  turn: prevLastTurn.turn + 1,
                  mobility: movePointsPerTurn,
                }
                turns = [...prevTurns, newTurn]
              } else {
                // console.log('branch: prevLastTurn: !(turnCost >= movePointsPerTurn)')
                const newTurn = {
                  ...prevLastTurn,
                  steps: [...prevLastTurn.steps, newStep],
                }
                turns = prevTurns.concat()
                turns[lastTurnIndex] = newTurn
              }
            } else if (movePointsLeftInFirstTurn > 0) {
              // console.log('branch: !prevLastTurn')
              const newTurn = {
                steps: [newStep],
                turn: 1,
                mobility: movePointsLeftInFirstTurn,
              }
              turns = [newTurn]
            } else {
              // console.log('branch: !prevLastTurn')
              const emptyTurn = {
                steps: [],
                turn: 1,
                mobility: 0,
              }
              const newTurn = {
                steps: [newStep],
                turn: 2,
                mobility: movePointsPerTurn,
              }
              turns = [emptyTurn, newTurn]
            }
            if (turns.length > maxTurns) {
              throw new Error('maxTurns (100) exceeded')
            }
            const newPath: PathTurnSteps = {
              totalCost: nextTotalCost,
              start: prevStart,
              end: { x: nextX, y: nextY },
              turns,
              blocked: blockedAt(nextX, nextY),
            }
            // console.log('newPath(setBest+queue.push)', JSON.stringify(newPath).replaceAll('"', ''))
            setBest(nextX, nextY, newPath)
            queue.push(newPath)
            // explore shorter paths first
            // for efficiency
            queue.sort(byTotalCostAsc)
          }
        }
      }
    }
  }

  // console.log(`getBest(${targetX}, ${targetY})`, getBest(targetX, targetY))
  return getBest(targetX, targetY)
}
function byTotalCostAsc(a: PathTurnSteps, b: PathTurnSteps): number {
  return a.totalCost - b.totalCost
}

function lastStepDefense(currentBestPath: Nullable<PathTurnSteps>): number | null {
  if (!currentBestPath) return null
  // Check if there are any turns in the path
  if (!currentBestPath.turns.length) {
    return null // No turns, return null
  }

  // Get the last turn
  const lastTurn = currentBestPath.turns[currentBestPath.turns.length - 1]

  // Check if there are any steps in the last turn
  if (!lastTurn.steps.length) {
    return null // No steps in the last turn, return null
  }

  // Get the last step in the last turn
  const lastStep = lastTurn.steps[lastTurn.steps.length - 1]

  // Return the defense value of the last step, or null if not available
  return lastStep.defense
}

function sumCost (sum: number, step: {cost : number}): number {
  return sum + step.cost
}
import type { State } from '../state/State.type'
import isConwayAdjacent from '../tile_position_xy/isConwayAdjacent'
import { samePosition } from '../tile_position_xy/samePosition'
import { EntityLayerId } from './entity_layer_id.enum'
import { EntityTypeId } from './entity_type_id.enum'
import getEntityTypeMetaById from './getEntityTypeMetaById'
import type { HasTaxiID } from './HasTaxiID'
import type { Entity } from './index'
import inSameTeam from './isSameTeam'

export default function hasSupplierInProximity(state: State, ent1: Entity): boolean {
  const { ents } = state
  // units in taxi do not resupply
  if ((ent1 as HasTaxiID).taxi_id) {
    return false
  }
  for (let index = ents.length; index--; ) {
    const ent2 = ents[index]
    if ((ent2 as HasTaxiID).taxi_id) {
      // units in taxi do not resupply
    } else {
      const entMeta2 = getEntityTypeMetaById(ent2.etype_id)
      if ('resupplies' in entMeta2) {
        if (inSameTeam(state, ent1, ent2)) {
          // is a building?
          if (
            entMeta2.layer_id === EntityLayerId.TerrainL2 &&
            samePosition(ent1, ent2) &&
            inSameTeam(state, ent1, ent2)
          ) {
            return true
          }
          // is APC?
          if (
            entMeta2.id === EntityTypeId.APC &&
            inSameTeam(state, ent1, ent2) &&
            isConwayAdjacent(ent1, ent2)
          ) {
            return true
          }
        }
      }
    }
  }
  return false
}

import type { HasAnnexPoints } from '../HasAnnexPoints.type'
import type { HasPlayerId } from '../player/has_player_id'
import type { HasTaxiID } from './HasTaxiID'
import type { Entity } from './index'
import type { Weapon } from './Weapon/Weapon.type'

export default function canUnitAttackEntity(
  unit: Entity,
  entity: Entity
): entity is Entity & HasAnnexPoints {
  const { player_id: unitPlayerId } = unit as Entity & HasPlayerId
  if ((entity as HasTaxiID).taxi_id) {
    return false
  }
  if (unitPlayerId && unitPlayerId !== (entity as HasPlayerId).player_id) {
    if ('hp' in entity) {
      if ('weapons' in unit && unit.weapons) {
        return !!unit.weapons.find(anyUsableWeapon)
      }
      return true
    }
  }
  return false
}

function anyUsableWeapon (w : Weapon) {
  if ('ammo' in w) {
    return w.ammo as number > 0
  }
  return true
}
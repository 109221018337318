import { enum_ } from 'valibot'

export enum WeaponInvalidId {
  // Valid = 0,
  OnlyTargetsAir = 2,
  OutOfAmmo,
  BelowMinRange,
  AboveMaxRange,
  ValueNotGreaterThanZero,
  CantCounterAttack,
}

export const WeaponInvalidIdSchema = enum_(WeaponInvalidId, 'Invalid Weapon Invalid ID')

// moveDraftMoveUnitOneTurnTowardsPosition(engine, draftMove, mouseDownPosition)

import Unexpected from "../../Exception/Unexpected.class"
import type { Engine } from "../engine/Engine.type"
import coord from "../tile_position_xy/coord"
import createLazyAttackableUnitAtGrid from "../tile_position_xy/createLazyAttackableUnitAtGrid"
import createLazyEfficientPathGrid from "../tile_position_xy/createLazyEfficientPathGrid"
import entityListToGridXY from "../tile_position_xy/entityListToGridXY"
import type { TilePositionXY } from "../tile_position_xy/TilePositionXY.type"
import type { DraftMove } from "./DraftMove.type"

// draftMove.destPosition = mouseDownPosition

export default function setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition(engine: Engine, draftMove: DraftMove, targetPosition: TilePositionXY): void {
  console.log('setDraftMoveDestPositionAndAttackPositionOneTurnTowardsPosition')
  const { state } = engine
  const { ents, width, height } = state
  const { unit } = draftMove

  const entityGridXY = entityListToGridXY(ents, width, height)
  const getEfficientPathToTile = createLazyEfficientPathGrid(width, height, entityGridXY, unit)

  const eptt = getEfficientPathToTile(targetPosition.x, targetPosition.y)

  if (!eptt) {
    // not sure if bug or undefined behavior
    // throw new Unexpected('!eptt')
    return
  }
  const firstTurn = eptt.turns[0]
  if (firstTurn) {
    const lastStep = firstTurn.steps.at(-1)
    if (lastStep) {
      draftMove.destPosition = coord(lastStep.x, lastStep.y)
    }
    // else {
    // // if there is not a lastStep,
    // // it is probably a ranged attack
    // // or the unit is already orthogonally adjacent
    //}
  }

  if (eptt.attack && eptt.turns.length <= 1) {
    console.log('eptt', eptt)
    console.log('draftMove.attackPosition = targetPosition', targetPosition)

    const attackableUnitGrid = createLazyAttackableUnitAtGrid(width, height, entityGridXY, unit)

    const attackableUnitsAtPosition = attackableUnitGrid(targetPosition.x, targetPosition.y)

    console.log('attackableUnitsAtPosition', attackableUnitsAtPosition)
    if (attackableUnitsAtPosition.length === 1) {
      draftMove.target = attackableUnitsAtPosition[0]
    } else if (attackableUnitsAtPosition.length > 1) {
      // the user will need to pick their target
      throw new Unexpected('attackableUnitsAtPosition.length > 1')
    }

    draftMove.attackPosition = targetPosition
  } else {
    console.log('draftMove.attackPosition = (no change)')
  }
}

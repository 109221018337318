/**
 * @link ./src/assets/svg/sprite/SolidShoePrints.svg
 **/
export const SolidShoePrints_viewBox_x = 0
export const SolidShoePrints_viewBox_y = 0
export const SolidShoePrints_viewBox_w = 40
export const SolidShoePrints_viewBox_h = 32
export const SolidShoePrints_viewBox_d = new Path2D(
  'M 26,0 C 22.01875,0 16,2 16,2 V 10 C 19,10 20.75,11 22.5,12 24.25,13 26,14 29,14 32.525,14 40,13 40,8 40,3 32,0 26,0 Z M 8,6 C 8,8.20625 9.79375,10 12,10 H 14 V 2 H 12 C 9.79375,2 8,3.79375 8,6 Z M 18,32 C 24,32 32,29 32,24 32,19 24.525,18 21,18 18,18 16.25,19 14.5,20 12.75,21 11,22 8,22 V 30 C 8,30 14.01875,32 18,32 Z M 0,26 C 0,28.20625 1.79375,30 4,30 H 6 V 22 H 4 C 1.79375,22 0,23.79375 0,26 Z'
)
export const SolidShoePrints_type = 'svg'

export const SolidFlag_viewBox_x = 0
export const SolidFlag_viewBox_y = 0
export const SolidFlag_viewBox_w = 448
export const SolidFlag_viewBox_h = 512
export const SolidFlag_viewBox_d = new Path2D(
  'M64 32C64 14.3 49.7 0 32 0S0 14.3 0 32L0 64 0 368 0 480c0 17.7 14.3 32 32 32s32-14.3 32-32l0-128 64.3-16.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30l0-247.7c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L64 48l0-16z'
)


export const TransportLoadedL_d = new Path2D(
  'M 2 1 L 8 1 L 8 11 L 14 11 L 14 17 L 2 17 L 2 1'
)
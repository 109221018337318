import { createMemo } from 'solid-js'
import type { Engine } from '../../lib/core/engine/Engine.type'
import type { Player } from '../../lib/core/player/Player.type'
import type { Nullable } from '../../typescript'

export function createAuthPlayerMemo(engine: Engine) {
  const memo = createMemo((): Nullable<Player> => {
    const { authPlayerId } = engine
    const authPlayer = authPlayerId
      ? engine.state.players.find((player: Player) => player.id === authPlayerId)
      : null
    return authPlayer
  })
  return memo
}

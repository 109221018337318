import { fallback, InferOutput, literal, nullable, object } from 'valibot'
import { AnnexPointsSchema } from '../../AnnexPoints.type'
import { entityTypeDefaults, EntityTypeMeta } from '../../EntityTypeMeta'
import { HasXYSchema } from '../../has_xy'
import { PlayerIdOrNullSchema } from '../../player/PlayerIdOrNull'
import { BASE_TILE_SIZE } from '../../view_ctx'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityTypeId } from '../entity_type_id.enum'
import { EntityIdSchema } from '../id.type'

const entDefaults = {
  etype_id: EntityTypeId.HQ,
  layer_id: EntityLayerId.TerrainL2,
  ap: 20,
  ap_ent_id: null,
} as const

export const HQEntitySchema = object({
  etype_id: literal(EntityTypeId.HQ),
  layer_id: literal(EntityLayerId.TerrainL2),
  id: EntityIdSchema,
  ...HasXYSchema.entries,
  player_id: PlayerIdOrNullSchema,
  ap: fallback(AnnexPointsSchema, 0),
  ap_ent_id: fallback(nullable(EntityIdSchema), null),
})
export type HQEntity = InferOutput<typeof HQEntitySchema>

export const HQEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  h: 2 * BASE_TILE_SIZE,
  id: EntityTypeId.HQ,
  dname: 'HQ',
  layer_id: EntityLayerId.TerrainL2,
  defense: 4, // maybe should be a bonus for owner only
  income: 10,
  resupplies: true,
  entDefaults,
}

export function createHQEntity(
  id: number,
  x: number,
  y: number,
  player_id: number | null
): HQEntity {
  return {
    ...entDefaults,
    id,
    x,
    y,
    player_id,
  }
}

import type { EntityTypeMeta } from '../EntityTypeMeta'
import type { EntityTypeId } from './entity_type_id.enum'
import entityTypeMetaList from './entityTypeMetaList.generated'

export default function getEntityTypeMetaById(entityTypeId: EntityTypeId): EntityTypeMeta {
  const entityTypeMeta = entityTypeMetaList.find((etm: EntityTypeMeta) => etm.id === entityTypeId)
  if (entityTypeMeta) {
    return entityTypeMeta
  }
  throw new Error('unexpected !entityTypeMeta')
}

import createSampleEntity from '../../../../entity/create_sample_entity'
import getEntityTypeMetaById from '../../../../entity/getEntityTypeMetaById'
import { Entity } from '../../../../entity/index'
import { shuffleArray } from '../../../../rng/index'
import { State } from '../../../../state/State.type'
import { TilePositionXY } from '../../../../tile_position_xy/TilePositionXY.type'
import { floor } from '../../../../util/math'
import { randomId } from '../../../../util/rand'
import { MapScript_FormData_Stage } from '../Stage.type'
import { MapScript_FormData_StageEntRule } from '../StageEntRule.type'
import { Clobber } from './Clobber.enum'

type WeightPoolState = {
  added: number
  targetRatio: number
  ratio: number
  progress: number
}

export default function runFormDataStage(
  state: State,
  stage: Readonly<MapScript_FormData_Stage>
  // formData: Readonly<MapScript_FormData>
): void {
  // console.log('stage', JSON.stringify(stage))
  const width: number = state.width || 1
  const height: number = state.height || 1
  const { rng, ents } = state
  const { coverageRatio, entWeights } = stage

  const tilesCount = width * height
  // const ents: Array<Entity> = []
  // add plains
  let x, y
  const allPositionsList: Array<TilePositionXY> = []
  for (let i = 0; i < tilesCount; i++) {
    x = i % width
    y = floor(i / width)
    allPositionsList.push({ x, y })
  }
  // console.log('rng', rng)
  shuffleArray(allPositionsList, rng)
  const targetCoverageCount: number = floor(tilesCount * coverageRatio)
  const weightsPool: Map<MapScript_FormData_StageEntRule, WeightPoolState> = new Map()
  const nextEntityWeightRule: () => MapScript_FormData_StageEntRule = (() => {
    // console.log('nextEntityWeightRule.entWeights.length', entWeights.length)
    if (entWeights.length === 1) {
      const value: MapScript_FormData_StageEntRule = entWeights[0]
      return (): MapScript_FormData_StageEntRule => value
    }
    const sum_weight: number = entWeights.reduce((sum, wr) => sum + wr.weight, 0)
    entWeights.forEach((wr) => {
      weightsPool.set(wr, {
        added: 0,
        targetRatio: wr.weight / sum_weight,
        ratio: 0,
        progress: 0,
      })
    })
    return (): MapScript_FormData_StageEntRule => {
      // console.log('nextEntityWeightRule')
      entWeights.sort((a: MapScript_FormData_StageEntRule, b: MapScript_FormData_StageEntRule) => {
        // console.log('weightsPool', weightsPool)
        const aState: WeightPoolState = weightsPool.get(a) as WeightPoolState
        const bState: WeightPoolState = weightsPool.get(b) as WeightPoolState
        aState.ratio = aState.added / targetCoverageCount
        bState.ratio = bState.added / targetCoverageCount
        aState.progress = aState.targetRatio - aState.ratio
        bState.progress = bState.targetRatio - bState.ratio
        return aState.progress - bState.progress
      })
      return entWeights[0]
    }
  })()
  const a: Array<Entity> = []
  for (let i = 0; i < targetCoverageCount; i++) {
    const position: TilePositionXY = allPositionsList[i]
    // const position: TilePositionXY = {x, y}
    const entityWeightRule = nextEntityWeightRule()
    const entityWeightRuleState: WeightPoolState = weightsPool.get(
      entityWeightRule
    ) as WeightPoolState
    if (entityWeightRuleState) {
      entityWeightRuleState.added++
    }

    const entityType = getEntityTypeMetaById(entityWeightRule.etype_id)
    const newEntity = createSampleEntity(entityType, null, randomId(ents, rng))
    a.push(newEntity)
    // if ((x + y) % 2 === 0) {
    //   const ent = createPlainEntity(randomId(ents, rng), x, y)
    //   ents.push(ent)
    // } else {
    //   const ent = createSeaEntity(randomId(ents, rng), x, y)
    //   ents.push(ent)
    // }
    const clobber: Clobber = entityWeightRule.clobber || stage.clobber
    upsertTile(state, newEntity, position, clobber)
  }
}

function upsertTile(
  state: State,
  newEntity: Entity,
  position: TilePositionXY,
  clobber: Clobber
): void {
  const { x, y } = position
  let painted = false
  newEntity.x = x
  newEntity.y = y
  const { ents } = state
  const layer_id = newEntity.layer_id
  const a: Array<Entity> = []

  // only one entity shall occupy any tile per layer
  // if there is an exception, it will probably be added here
  for (let index = 0, ent_len = ents.length; index < ent_len; index++) {
    const ent = ents[index]
    // console.log('runFormDataStage.upsertTile', ent.layer_id, ent.layer_id, ent.x, ent.y)
    // console.table([ent, newEnt])
    if (layer_id == ent.layer_id && x == ent.x && y == ent.y) {
      if (painted) {
        // console.log('runFormDataStage.upsertTile', 'drop because duplicate')
        continue // drop because duplicate
      } else {
        // console.log('runFormDataStage.upsertTile', 'ents[index] = newEnt')
        painted = true
        if (clobber === Clobber.Yes) ents[index] = newEntity
      }
    }
    a.push(ents[index])
  }
  if (!painted) {
    // console.log('runFormDataStage.upsertTile', "Added: no replacible tile found")
    a.push(newEntity)
    // console.log('runFormDataStage.upsertTile', newEnt)
  }

  state.ents = a
}

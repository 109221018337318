import { Key } from "@solid-primitives/keyed"
import { Accessor, Component, createEffect, createMemo, createSignal } from "solid-js"
import { createMutable } from "solid-js/store"
import bindEngineToComponent from "../../lib/canvas/bind_engine_to_component"
import createEngineForUI from "../../lib/core/engine/createEngineForUI"
import { Engine } from "../../lib/core/engine/Engine.type"
import createSampleEntity from "../../lib/core/entity/create_sample_entity"
import { LightTankEntityTypeMeta } from "../../lib/core/entity/EntityType/LightTank"
import { Player } from "../../lib/core/player/Player.type"
import { Nullable } from "../../typescript"
import EngineContext from "../EngineContext"
import SingleSpriteIcon from "../MapEditor/SingleSpriteIcon"

type PlayerIdOrNull = Nullable<number>

interface Props {
  value: PlayerIdOrNull
  onChange: (value: PlayerIdOrNull) => void
  players: Array<Player>,
}

const SelectPlayerIdDropDownButton: Component<Props> = (props) => {

  const iconEntityType = LightTankEntityTypeMeta

  const [isDropDownOpen, setIsDropDownOpen] = createSignal<boolean>(false)

  const selectedPlayer = createMemo<Nullable<Player>>(() => {
    return props.players.find((player) => player.id === props.value)
  })

  const engine: Engine = createMutable(createEngineForUI())

  // createEffect(() => {
  //   console.log('createEffect.selectedPlayer', JSON.stringify(selectedPlayer()))
  // })

  // const dirty = engine.cache[1]
  // createEffect(() => {
  //   console.log('createEffect.props.value', props.value)
  //   dirty(EngineCache.EntityList)
  //   dirty(EngineCache.State)
  //   dirty(EngineCache.PlayerList)
  //   dirty(EngineCache.ViewCtx)
  //   dirty(EngineCache.Cursor)
  // })

  createEffect(() => {
    engine.state.players = [
      ...props.players
    ]
    // console.log('createEffect.engine.state.players', structuredClone(unwrap(engine.state.players)))
  })

  bindEngineToComponent(engine)

  return <EngineContext.Provider value={engine}>
    <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle w-100" type="button" aria-expanded="false"
        onClick={() => { setIsDropDownOpen(!isDropDownOpen()) }}
      >
        {!props.value && 'All Players'}
        {<Key each={props.players} by="id">{(player: Accessor<Player>) => <SingleSpriteIcon
          tileSize={24}
          entity={createSampleEntity(iconEntityType, player().id)}
          style={{
            display: (player().id === props.value || !props.value) ? 'inline' : 'none',
            'padding-left': '5px'
          }}
        />}</Key>}
        {props.value && (selectedPlayer()?.dname || 'unknown')}
      </button>
      <ul classList={{ 'dropdown-menu': true, show: isDropDownOpen(), 'w-100': true }}>
        <li>
          <button class="dropdown-item" type="button"
            onClick={() => {
              props.onChange(null)
              setIsDropDownOpen(false)
            }}
          >All Players</button>
        </li>
        <Key each={props.players} by="id">
          {(player: Accessor<Player>) => <li>
            <button class="dropdown-item" type="button"
              onClick={() => {
                props.onChange(player().id)
                setIsDropDownOpen(false)
              }}
            ><SingleSpriteIcon
                tileSize={24}
                entity={createSampleEntity(iconEntityType, player().id)}
                style={{ 'padding-right': '5px' }}
              /> {player().dname}</button>
          </li>}
        </Key>
      </ul>
    </div>
  </EngineContext.Provider>
}

export default SelectPlayerIdDropDownButton


import type { Nullable } from '../../../typescript'
import type { Player } from '../player/Player.type'
import type { ProfileId } from '../ProfileId.type'
import { Engine } from './Engine.type'

export default function setAuthPlayerIdFromProfileId(
  engine: Engine,
  profileId: Nullable<ProfileId>
): Nullable<Player> {
  if (profileId) {
    const player = engine.state.players.find((player: Player) => player.profile_id === profileId)
    engine.authPlayerId = player?.id || null
    return player
  }
  return null
}

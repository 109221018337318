import { FaSolidX } from 'solid-icons/fa'
import { Component, createMemo, For, Show, useContext } from 'solid-js'
import type { Engine } from '../../lib/core/engine/Engine.type'
import createSampleEntity from '../../lib/core/entity/create_sample_entity'
import type { EntityTypeId } from '../../lib/core/entity/entity_type_id.enum'
import entityTypeMetaList from '../../lib/core/entity/entityTypeMetaList.generated'
import getEntityTypeMetaById from '../../lib/core/entity/getEntityTypeMetaById'
import hasSupplierInProximity from '../../lib/core/entity/hasSupplierInProximity'
import type { EntityTypeMeta } from '../../lib/core/EntityTypeMeta'
import formatGameMoney from '../../lib/core/player/formatGameMoney'
import type { HasPlayerId } from '../../lib/core/player/has_player_id'
import type { Player } from '../../lib/core/player/Player.type'
import { min } from '../../lib/core/util/math'
import EngineContext from '../EngineContext'
import SingleSpriteIcon from '../MapEditor/SingleSpriteIcon'

interface Props {
  when: boolean
  setOpen: (newValue: boolean) => void
  player: Player
  class: string
}

function increment<K>(map: Map<K, number>, key: K): void {
  map.set(key, (map.get(key) || 0) + 1)
}

function add<K>(map: Map<K, number>, key: K, addValue : number): void {
  map.set(key, (map.get(key) || 0) + addValue)
}

type EntityTypeFinancialSummary = {
  meta: EntityTypeMeta,
  count: number,
  income: number,
  repair: number
}

const PlayerMoneyCornerWidget: Component<Props> = (props) => {
  const engine : Engine = useContext(EngineContext)

  const entsSummary = createMemo<Array<EntityTypeFinancialSummary>>(() => {
    // const data = []
    const counts = new Map<EntityTypeId, number>()
    const nextRepairCost = new Map<EntityTypeId, number>()
    const incomes = new Map<EntityTypeId, number>()
    const playerId = props.player.id
    const { state } = engine
    const { ents } = state

    for (let index = 0; index < ents.length; index++) {
      const entity = ents[index];
      if ((entity as HasPlayerId).player_id === playerId) {
        const entityMeta = getEntityTypeMetaById(entity.etype_id)

        const { price, entDefaults } = entityMeta
        const {
          hp: defaultHp,
          // fuel: defaultFuel,
          // mobility: defaultMobility,
        } = entDefaults as {
          fuel?: number
          hp?: number
          mobility?: number
        }

        increment(counts, entity.etype_id)

        if ('hp' in entity && price && defaultHp) {
          const { hp: prevHp } = entity
          if (entity.hp < defaultHp && hasSupplierInProximity(state, entity)) {
            const nextHp = min(defaultHp, prevHp + 2)
            const hpAdded = nextHp - prevHp
            const nextHpCreditCost = (hpAdded * price) / 10
            add(nextRepairCost, entity.etype_id, nextHpCreditCost)
          }
        }

        if (entityMeta.income) {
          add(incomes, entity.etype_id, entityMeta.income)
        }
      }
    }
    return entityTypeMetaList.reduce((data: Array<EntityTypeFinancialSummary>, entityTypeMeta : EntityTypeMeta) => {
      if (counts.has(entityTypeMeta.id)) {
        data.push({
          meta: entityTypeMeta,
          count: counts.get(entityTypeMeta.id) || 0,
          income: incomes.get(entityTypeMeta.id) || 0,
          repair: nextRepairCost.get(entityTypeMeta.id) || 0,
        })
      }
      return data
    }, [])
  })

  const netIncome = createMemo<number>(() => {
    return entsSummary().reduce((sum, row) => sum + row.income - row.repair, 0)
  })

  return (
    <Show when={props.when}>
      <div class="clear-both" />
      <div class={`cw cw-player-money m-2 ${props.class}`}>
        <table>
          <thead>
            <tr>
              <th />
              <th class="text-end">Count</th>
              <th class="text-end">Income</th>
              <th class="text-end">Repairs</th>
            </tr>
          </thead>
          <tbody>
            <For each={entsSummary()} children={(entSummary) => {
              return (<tr>
                <td title={entSummary.meta.dname}>
                  <SingleSpriteIcon 
                    entity={createSampleEntity(entSummary.meta, props.player.id)}
                    tileSize={16}
                  />
                </td>
                <td class="text-end text-monospace">{entSummary.count}</td>
                <td class="text-end">
                  <Show when={entSummary.income !== 0}>
                    <span class="text-muted ps-1">₵</span>{formatGameMoney(entSummary.income)}
                  </Show>
                </td>
                <td class="text-end">
                  <Show when={entSummary.repair !== 0}>
                    (<span class="text-muted ps-1">₵</span>{formatGameMoney(entSummary.repair)})
                  </Show>
                </td>
              </tr>)
            }}/>
          </tbody>
        </table>
        <div class="float-start">
          <span class="text-muted">Net Income:</span>
          <span class="text-muted ps-1">₵</span>{formatGameMoney(netIncome())}
        </div>
        <button class="btn btn-secondary btn-sm float-end" onClick={() => {
          props.setOpen(false)
        }}><FaSolidX /> Close</button>
      </div>
    </Show>
  )
}

export default PlayerMoneyCornerWidget

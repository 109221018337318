import { Component, createEffect, createSignal, useContext } from "solid-js"
import { Engine } from "../../lib/core/engine/Engine.type"
import { floor } from "../../lib/core/util/math"
import preventCursorSelectText from "../../lib/dom/event/prevent_mouse_select_text"
import { OneOrZero } from "../../rx/signal/create_one_or_zero_signal"
import EngineContext from "../EngineContext"

const ZoomCardWidget: Component = () => {
  const engine : Engine = useContext(EngineContext)
  const { viewCtx } = engine

  let timeout : NodeJS.Timeout;

  const [hidden, setHidden] = createSignal<OneOrZero>(0)

  createEffect(() => {
    // console.log('ZoomCardWidget.createEffect.lastZoomAt', viewCtx.lastZoomAt)
    const lastZoomAt = viewCtx.lastZoomAt
    setHidden(0)
    clearTimeout(timeout)
    if (lastZoomAt) {
      timeout = setTimeout(setHidden, 1000, 1);
    }
  })

  return <h4
    id="zoom"
    ref={(elem) => {
      preventCursorSelectText(elem)
    }}
    class="widget top-right card card-title text-center m-2 p-2 top-right pe-none"
    classList={{
      'fade-out': hidden() as unknown as boolean,
      'd-none': viewCtx.lastZoomAt === 0,
    }}
  >Zoom: {floor(viewCtx.zoom)}%</h4>
}

export default ZoomCardWidget

import { trackDeep } from '@solid-primitives/deep'
import { createEffect, on } from 'solid-js'
import { modifyMutable, produce, unwrap } from 'solid-js/store'
import type { Nullable } from '../../../typescript'
import createLayerMeta from '../../core/create_layer_meta'
import calculateDraftMoveAttackEstimates from '../../core/draft_move/calculateDraftMoveAttackEstimates'
import createDraftMove from '../../core/draft_move/createDraftMove'
import type { DraftMove } from '../../core/draft_move/DraftMove.type'
import { Engine } from '../../core/engine/Engine.type'
import type { HasTaxiID } from '../../core/entity/HasTaxiID'
import type { Entity } from '../../core/entity/index'
import isEntityHasMovesLeft from '../../core/entity/isEntityHasMovesLeft'
import { SelectedToolId } from '../../core/map_editor/SelectedToolId.enum'
import type { HasPlayerId } from '../../core/player/has_player_id'
import {
  renderSpriteByIdMap,
  type MakeRenderSpriteFunction,
  type RenderSpriteFunction,
} from '../../core/render_sprite'
import createIsPositionReachableInOneTurnV2 from '../../core/tile_position_xy/createIsPositionReachableInOneTurnV2'
import createLazyEfficientPathGrid from '../../core/tile_position_xy/createLazyEfficientPathGrid'
import entityListToGridXY from '../../core/tile_position_xy/entityListToGridXY'
import type {
  PathTurnSteps,
  PathTurnSteps_Step,
} from '../../core/tile_position_xy/findMostEfficientPathToTileV2'
import { samePosition } from '../../core/tile_position_xy/samePosition'
import type { TilePositionXY } from '../../core/tile_position_xy/TilePositionXY.type'
import { floor, max, min, PI } from '../../core/util/math'
import { BASE_TILE_SIZE } from '../../core/view_ctx'
import { SolidShoePrints_viewBox_d } from '../../svg/sprites_data_manual'
import bindCanvasToEngine from '../bind_canvas_to_engine'
import { createCanvasElement, createOffscreenCanvas } from '../create_canvas'
import { disableCanvasSmoothing } from '../disable_canvas_smoothing'
import drawUnitPathArrowHeadToPosition from '../draw/cursor/drawUnitPathArrowHeadToPosition'
import defaultErrorRender from '../draw/default_render_error'
import { getCanvas2dContext, type EitherRenderingContext2D } from '../get_canvas_2d_context'

const name = 'DrawMovementGrid'
const relZIndex = 0

// const blue = 'rgba(0, 0, 255, 1)'
// const cyan = 'rgba(0, 255, 255, 1)'

export default function addDrawMovementGridCanvas(engine: Engine): void {
  // console.log(name)
  let isPositionReachableInOneTurn: Nullable<(x: number, y: number) => boolean> = null
  let canUnitDirectAttackThisTurn: Nullable<(x: number, y: number) => boolean> = null
  let getEfficientPathToTile: Nullable<(x: number, y: number) => PathTurnSteps | null> = null
  let entityGridXY: ReturnType<typeof entityListToGridXY> | null = null

  const lm = createLayerMeta()
  const elem = createCanvasElement()
  const buffer1 = createOffscreenCanvas(1, 1)
  const moveLinearGradientBuffer = createOffscreenCanvas(1, 1)
  const directAttackLinearGradientBuffer = createOffscreenCanvas(1, 1)
  const arrowBuffer = createOffscreenCanvas(1, 1)
  const unitBuffer = createOffscreenCanvas(1, 1)
  const attackTargetIconBuffer = createOffscreenCanvas(1, 1)
  elem.setAttribute('name', name)

  createEffect(on(() => engine.selectedPosition, (selectedPosition : TilePositionXY | null) => {
    // console.log('createEffect(on(() => engine.selectedPosition')
    selectedPosition = unwrap(selectedPosition)
    // console.log(
    //   `[${name}].effect.selectedPosition`,
    //   deepClone({ selectedPosition, draftMove: engine.draftMove })
    // )
    let draftUnitEntity: Entity | null = null
    const { authPlayerId, draftMove, selectedPositionEnts } = engine
    if (draftMove) {
      // console.log('already have draftMove')
      if (selectedPosition && !samePosition(selectedPosition, draftMove.startPosition)) {
        // console.warn('selectedPosition different than draftMove', {
        //   selectedPosition,
        //   draftMoveStartPosition: draftMove.startPosition,
        //   samePosition: samePosition(selectedPosition, draftMove.startPosition),
        // })
      }
    } else if (selectedPosition) {
      const selectedPositionEntsThatCanMove = unwrap(selectedPositionEnts).filter((ent) => {
        // player must select "unload" first
        if ((ent as HasTaxiID).taxi_id) {
          return false
        }
        if ((ent as HasPlayerId).player_id === authPlayerId) {
          return isEntityHasMovesLeft(ent)
        }
        return false
      })
      // console.log('selectedPositionEntsThatBelongToAuthPlayer', selectedPositionEnts)
      if (selectedPositionEntsThatCanMove.length === 1) {
        draftUnitEntity = unwrap(selectedPositionEntsThatCanMove[0])
        // console.log(`[${name}].effect.draftUnitEntity`, deepClone(draftUnitEntity))

        // console.log(`[${name}].effect.isPositionReachableInOneTurn`, isPositionReachableInOneTurn)
      } else {
        // console.log(
        //   'selectedPositionEntsThatBelongToAuthPlayer',
        //   deepClone(selectedPositionEnts)
        // )
        entityGridXY = null
        isPositionReachableInOneTurn = null
      }

      modifyMutable(
        engine,
        produce((engine: Engine) => {
          if (draftUnitEntity) {
            engine.selectedTool = SelectedToolId.MoveUnit
            // console.log('createDraftMove', deepClone({ draftUnitEntity }))
            // console.log('engine.draftMove = createDraftMove(draftUnitEntity)')
            engine.draftMove = createDraftMove(draftUnitEntity)
            calculateDraftMoveAttackEstimates(engine)
          } else {
            engine.selectedTool = SelectedToolId.Inspect
          }
        })
      )

      // console.log({ hoveredPosition, entityGridXY, draftUnitEntity })
      lm.until = 0
    } else {
      isPositionReachableInOneTurn = null
    }
  }))

  createEffect(on(() => engine.draftMove, (draftMove: DraftMove | null) => {
    // console.log('createEffect(on(() => engine.draftMove')
    if (draftMove) {
      const { width, height, ents } = engine.state
      entityGridXY = entityListToGridXY(ents, width, height)
      isPositionReachableInOneTurn = createIsPositionReachableInOneTurnV2(
        entityGridXY,
        width,
        height,
        draftMove.unit
      )
    } else {
      entityGridXY = null
      isPositionReachableInOneTurn = null
    }
    lm.until = 0
  }))

  const getTargetPosition = () => engine.draftMove && (engine.draftMove?.destPosition || engine.hoveredPosition)
  createEffect(on(getTargetPosition, () => {
    // console.log('createEffect(on(getTargetPosition')
    lm.until = 0
  }))

  createEffect(on(() => engine.draftMove?.unit, (unit : Nullable<Entity>) => {
    // console.log('createEffect(on(() => engine.draftMove?.unit')
    const { state } = engine
    const { width, height, ents } = state
    if (unit) {
      entityGridXY = entityListToGridXY(ents, width, height)
      getEfficientPathToTile = createLazyEfficientPathGrid(width, height, entityGridXY, unit)
      canUnitDirectAttackThisTurn = (x: number, y: number) => {
        if (getEfficientPathToTile) {
          const upts = getEfficientPathToTile(x, y)
          // upts.turns.length === 1 : unit can walk up to the unit and attack
          // upts.turns.length === 0 : unit is orthogonally adjacent
          if (upts?.attack && upts.turns.length <= 1) {
            return true
          }
        }
        return false
      }
    } else {
      getEfficientPathToTile = null
      canUnitDirectAttackThisTurn = null
    }
    lm.until = 0
  }))

  let stateWidth = 1
  let stateHeight = 1
  createEffect(() => {
    // console.log('createEffect(zIndex,width,height)')
    elem.style.zIndex = (engine.viewCtx.zIndex + relZIndex).toString()
    stateWidth = engine.state.width
    stateHeight = engine.state.height
  })
  createEffect(() => {
    // console.log('createEffect(trackDeep(viewCtx))')
    trackDeep(engine.viewCtx)
    lm.until = 0
  })

  let draftMoveUnit: Entity | null = null
  let unitRenderFn: RenderSpriteFunction | null = null
  createEffect(() => {
    // console.log('createEffect(unitRenderFn)')
    const { draftMove } = engine
    if (draftMove) {
      draftMoveUnit = unwrap(draftMove.unit)
      const makeRenderSprite: Nullable<MakeRenderSpriteFunction> =
        renderSpriteByIdMap[draftMoveUnit.etype_id]
      unitRenderFn = makeRenderSprite ? makeRenderSprite(engine, draftMoveUnit) : defaultErrorRender
    } else {
      unitRenderFn = null
    }
    lm.until = 0
    // console.log('unitRenderFn', unitRenderFn)
  })

  function render(timeStamp: number) {
    lm.ts = timeStamp

    // frames have indicated they
    // plan on rendering the same image

    // if (!(timeStamp > 0)) console.log(`${name}[${entityLayerIdListText}]`, '!(timeStamp > 0)', timeStamp)

    if (lm.until > timeStamp) {
      return
    }
    // console.log(`${name}.render`)

    const {
      renderWidthPx,
      renderHeightPx,
      tile_size,
      tile_gap_px,
      canvas_edge_buffer,
      pan_x,
      pan_y,
    } = unwrap(engine.viewCtx)
    const tile_size_plus_gap: number = tile_size + tile_gap_px

    // buffer1 will be the full map, unpanned
    const bufferWidthPx =
      tile_size * stateWidth + tile_gap_px * (stateWidth - 1) + 2 * canvas_edge_buffer
    const bufferHeightPx =
      tile_size * stateHeight + tile_gap_px * (stateHeight - 1) + 2 * canvas_edge_buffer

    const attackTargetIconCtx = getCanvas2dContext(attackTargetIconBuffer)
    if (!attackTargetIconCtx) return
    const unitCtx = getCanvas2dContext(unitBuffer)
    if (!unitCtx) return
    const ctx1 = getCanvas2dContext(buffer1)
    if (!ctx1) return
    const ctx0 = getCanvas2dContext(elem)
    if (!ctx0) return
    const ctxLinearGradient = getCanvas2dContext(moveLinearGradientBuffer)
    if (!ctxLinearGradient) return
    const ctxDirectAttackLinearGradient = getCanvas2dContext(directAttackLinearGradientBuffer)
    if (!ctxDirectAttackLinearGradient) return
    const arrowCtx = getCanvas2dContext(arrowBuffer)
    if (!arrowCtx) return

    // IDK what this was for, but it caused the animation to stop running
    // lm.until = timeStamp + EXPIRE_NEVER
    lm.until = timeStamp + 500

    // reset buffer1
    buffer1.width = bufferWidthPx
    buffer1.height = bufferHeightPx
    ctx1.clearRect(0, 0, bufferWidthPx, bufferHeightPx)

    moveLinearGradientBuffer.width = bufferWidthPx
    moveLinearGradientBuffer.height = bufferHeightPx
    directAttackLinearGradientBuffer.width = bufferWidthPx
    directAttackLinearGradientBuffer.height = bufferHeightPx
    arrowBuffer.width = bufferWidthPx
    arrowBuffer.height = bufferHeightPx
    unitBuffer.width = bufferWidthPx
    unitBuffer.height = bufferHeightPx
    attackTargetIconBuffer.width = tile_size * 2
    attackTargetIconBuffer.height = tile_size * 2

    if (isPositionReachableInOneTurn) {
      ctxLinearGradient.clearRect(0, 0, bufferWidthPx, bufferHeightPx)
      if (ctxLinearGradient) {
        const gradient = ctxLinearGradient.createLinearGradient(0, bufferWidthPx, bufferHeightPx, 0)
        gradient.addColorStop(0, `rgba(0, 0, 255, 0.5)`)
        gradient.addColorStop(0.38, `rgba(0, 255, 255, 0.55)`)
        gradient.addColorStop(0.42, `rgba(0, 255, 255, 0.5)`)
        gradient.addColorStop(0.6, `rgba(0, 255, 255, 0.4)`)
        gradient.addColorStop(1, `rgba(0, 0, 255, 0.5)`)
        ctxLinearGradient.fillStyle = gradient
        ctxLinearGradient.fillRect(0, 0, bufferWidthPx, bufferHeightPx)
      }

      disableCanvasSmoothing(ctx1)

      ctx1.fillStyle = 'blue'
      // ctx1.globalAlpha = 0.33
      // ctx1.globalCompositeOperation = LUMINOSITY

      // buffer1
      for (let x = 0; x < stateWidth; x++) {
        for (let y = 0; y < stateHeight; y++) {
          if (isPositionReachableInOneTurn(x, y)) {
            const dx: number = canvas_edge_buffer + x * tile_size_plus_gap
            const dy: number = canvas_edge_buffer + y * tile_size_plus_gap

            // ctx1.fillRect(dx, dy, tile_size, tile_size)
            ctx1.drawImage(moveLinearGradientBuffer, dx, dy, tile_size, tile_size)
          }
        }
      }

      ctx1.globalAlpha = 0.1
      ctx1.fillStyle = 'purple'

      // ctx1.save()

      const scaleFactor = tile_size / BASE_TILE_SIZE / 3
      // const offsetX1 = min(2, tile_size / 8)
      // const offsetY1 = min(2, tile_size / 8)
      const offsetX1 = (1 * tile_size) / 9 + 1
      const offsetY1 = (1 * tile_size) / 9 + 1
      const offsetX2 = (4.1 * tile_size) / 9 + 1
      const offsetY2 = (4.1 * tile_size) / 9 + 1
      // const offsetX3 = (4 * tile_size) / 7
      // const offsetY3 = (4 * tile_size) / 7

      // buffer2 (TODO make seperate buffer?)
      for (let x = 0; x < stateWidth; x++) {
        for (let y = 0; y < stateHeight; y++) {
          if (isPositionReachableInOneTurn(x, y)) {
            const dx: number = canvas_edge_buffer + x * tile_size_plus_gap
            const dy: number = canvas_edge_buffer + y * tile_size_plus_gap
            ctx1.translate(dx + offsetX1, dy + offsetY1)
            ctx1.scale(scaleFactor, scaleFactor)
            ctx1.fill(SolidShoePrints_viewBox_d)
            ctx1.resetTransform()

            ctx1.translate(dx + offsetX2, dy + offsetY2)
            ctx1.scale(scaleFactor, scaleFactor)
            ctx1.fill(SolidShoePrints_viewBox_d)
            ctx1.resetTransform()

            // ctx1.translate(dx + offsetX3, dy + offsetY3)
            // ctx1.scale(scaleFactor, scaleFactor)
            // ctx1.fill(SolidShoePrints_viewBox_d)
            // ctx1.resetTransform()
          }
        }
      }

      // arrowCtx (TODO make seperate buffer?)
      const arrowWidth = floor(tile_size * (3 / 5))
      // const halfArrowWidth = floor(arrowWidth / 2)
      const gap1 = floor((tile_size - arrowWidth) / 2)
      const rrRadii = floor(arrowWidth / 2)
      const halfTileSize = floor(tile_size / 2)
      arrowCtx.globalAlpha = 1
      arrowCtx.fillStyle = 'red'
      // V2 code

      const targetPosition = getTargetPosition()

      // draw red line with arrow head
      const upts = targetPosition && getEfficientPathToTile?.(targetPosition?.x, targetPosition.y)
      if (upts && upts.totalCost > 0) {
        // draw red dots
        drawRedRoundRect(
          arrowCtx,
          arrowWidth,
          canvas_edge_buffer,
          tile_size_plus_gap,
          gap1,
          rrRadii,
          upts.start.x,
          upts.start.y
        )
        upts.turns.forEach((turn) => {
          turn.steps.forEach((step) => {
            drawRedRoundRect(
              arrowCtx,
              arrowWidth,
              canvas_edge_buffer,
              tile_size_plus_gap,
              gap1,
              rrRadii,
              step.x,
              step.y
            )
          })
        })

        // connect red dots
        upts.turns.forEach((turn, turnIndex) => {
          let prevStep: PathTurnSteps_Step | null = null
          turn.steps.forEach((step) => {
            if (prevStep || turnIndex === 0) {
              const { x: x1, y: y1 } = step
              const { x: x2, y: y2 } = prevStep || upts!.start
              const minX = min(x1, x2)
              const minY = min(y1, y2)
              const dx: number = canvas_edge_buffer + minX * tile_size_plus_gap + gap1
              const dy: number = canvas_edge_buffer + minY * tile_size_plus_gap + gap1
              const w = x1 === x2 ? arrowWidth : tile_size_plus_gap + arrowWidth
              const h = y1 === y2 ? arrowWidth : tile_size_plus_gap + arrowWidth
              arrowCtx.beginPath()
              arrowCtx.roundRect(dx, dy, w, h, rrRadii)
              arrowCtx.fill()
            }
            //
            prevStep = step
          })
        })

        // draw arrow heads
        let prevTurnLastStep: TilePositionXY | null = upts.start
        upts.turns.forEach((turn) => {
          const lastStep = turn.steps[turn.steps.length - 1]
          const prevStep = turn.steps[turn.steps.length - 2] || prevTurnLastStep

          // if (turn.steps.length === 1 && turn.turn > 1) {
          //   // skip arrow?
          // } else
          if (lastStep && prevStep) {
            // arrowCtx.resetTransform()
            drawUnitPathArrowHeadToPosition(
              prevStep,
              lastStep,
              arrowCtx,
              tile_size,
              tile_size_plus_gap,
              halfTileSize
            )

            arrowCtx.resetTransform()
          } else {
            // console.log('upts', { lastStep, prevStep }, upts)
          }
          prevTurnLastStep = lastStep
        })

        // // draw debug text
        // upts.turns.forEach((turn) => {
        //   turn.steps.forEach((step) => {
        //     const { x: x1, y: y1 } = step
        //     const dx1: number = canvas_edge_buffer + x1 * tile_size_plus_gap + gap1
        //     const dy1: number = canvas_edge_buffer + y1 * tile_size_plus_gap + gap1

        //     const lineHeight = 15
        //     let line = 0
        //     arrowCtx.font = 'bold 16px monospace'
        //     arrowCtx.fillStyle = 'black'
        //     ctx0.filter = 'drop-shadow(5px 5px 10px white)'
        //     arrowCtx.fillText(`[${step.x},${step.y}]`, dx1, dy1 + gap1 + line++ * lineHeight)
        //     arrowCtx.fillText(`cost:${step.cost}`, dx1, dy1 + gap1 + line++ * lineHeight)
        //     // arrowCtx.fillText(`rema:${step.remain}`, dx1, dy1 + gap1 + line++ * lineHeight)
        //     arrowCtx.fillText(`turn:${turn.turn}`, dx1, dy1 + gap1 + line++ * lineHeight)
        //   })
        // })

        if (unitRenderFn && draftMoveUnit) {
          const destPosition = engine.draftMove?.destPosition
          if (destPosition) {
            const dx: number = canvas_edge_buffer + destPosition.x * tile_size_plus_gap
            const dy: number = canvas_edge_buffer + destPosition.y * tile_size_plus_gap
            unitRenderFn(unitCtx, lm, draftMoveUnit, dx, dy, tile_size)
          }
        }
      }
    }

    if (canUnitDirectAttackThisTurn) {
      ctx1.globalAlpha = 1
      // ctx1.globalCompositeOperation = DEFAULT_GLOBAL_COMPOSITE_OPERATION
      ctxDirectAttackLinearGradient.clearRect(0, 0, bufferWidthPx, bufferHeightPx)
      if (ctxDirectAttackLinearGradient) {
        const gradient = ctxDirectAttackLinearGradient.createLinearGradient(
          0,
          bufferWidthPx,
          bufferHeightPx,
          0
        )

        // gradient.addColorStop(0, `rgba(0, 0, 255, 0.5)`)
        // gradient.addColorStop(0.38, `rgba(0, 255, 255, 0.55)`)
        // gradient.addColorStop(0.42, `rgba(0, 255, 255, 0.5)`)
        // gradient.addColorStop(0.6, `rgba(0, 255, 255, 0.4)`)
        // gradient.addColorStop(1, `rgba(0, 0, 255, 0.5)`)

        gradient.addColorStop(0, `rgba(255, 0, 32, 0.5)`)
        gradient.addColorStop(0.38, `rgba(255, 32, 32, 0.55)`)
        gradient.addColorStop(0.42, `rgba(255, 32, 32, 0.5)`)
        gradient.addColorStop(0.6, `rgba(255, 32, 32, 0.4)`)
        gradient.addColorStop(1, `rgba(255, 0, 32, 0.5)`)

        ctxDirectAttackLinearGradient.fillStyle = gradient
        // ctxDirectAttackLinearGradient.fillStyle = 'red'
        ctxDirectAttackLinearGradient.fillRect(0, 0, bufferWidthPx, bufferHeightPx)
      }

      disableCanvasSmoothing(ctx1)

      ctx1.fillStyle = 'red'
      // ctx1.globalAlpha = 0.33
      // ctx1.globalCompositeOperation = LUMINOSITY

      // buffer1
      for (let x = 0; x < stateWidth; x++) {
        for (let y = 0; y < stateHeight; y++) {
          if (canUnitDirectAttackThisTurn(x, y)) {
            // console.log('canUnitDirectAttack(x, y)', x, y)
            const dx: number = canvas_edge_buffer + x * tile_size_plus_gap
            const dy: number = canvas_edge_buffer + y * tile_size_plus_gap

            // ctx1.fillRect(dx, dy, tile_size, tile_size)
            ctx1.drawImage(directAttackLinearGradientBuffer, dx, dy, tile_size, tile_size)
          }
        }
      }
    }

    // draw crosshairs
    const { hoveredPosition } = engine
    const attackPosition : Nullable<TilePositionXY> = engine.draftMove?.attackPosition
      || ((hoveredPosition && canUnitDirectAttackThisTurn && canUnitDirectAttackThisTurn(hoveredPosition.x, hoveredPosition.y)) ? hoveredPosition : null)
      // || coord(6,6)

    if (attackPosition) {
      // console.log('attackPosition', attackPosition)
      const sWidth = attackTargetIconBuffer.width
      const sHeight = attackTargetIconBuffer.height
      const sWCut1 = (sWidth - tile_size) / 2
      const sHCut1 = (sHeight - tile_size) / 2
      const sWCut2 = (sWidth - tile_size) * (3 / 8)
      const sHCut2 = (sHeight - tile_size) * (3 / 8)
      const sWCut3 = (sWidth - tile_size) * (5 / 8)
      const sHCut3 = (sHeight - tile_size) * (5 / 8)
      const dx: number = canvas_edge_buffer + (attackPosition.x * tile_size_plus_gap) - sWCut1
      const dy: number = canvas_edge_buffer + (attackPosition.y * tile_size_plus_gap) - sHCut1
      const radius = floor(tile_size * 0.75)
      attackTargetIconCtx.strokeStyle = 'white'
      attackTargetIconCtx.lineWidth = max(2, floor(tile_size / 16))
      // console.log('attackTargetIconCtx.lineWidth', attackTargetIconCtx.lineWidth)
      attackTargetIconCtx.beginPath()
      attackTargetIconCtx.arc(tile_size, tile_size, radius, 0, PI * 2)
      attackTargetIconCtx.stroke()

      attackTargetIconCtx.beginPath()

      attackTargetIconCtx.moveTo(sWCut2, sHCut2)
      attackTargetIconCtx.lineTo(sWCut3, sHCut3)

      attackTargetIconCtx.moveTo(sWidth - sWCut2, sHeight - sHCut2)
      attackTargetIconCtx.lineTo(sWidth - sWCut3, sHeight - sHCut3)

      attackTargetIconCtx.moveTo(sWidth - sWCut2, sHCut2)
      attackTargetIconCtx.lineTo(sWidth - sWCut3, sHCut3)

      attackTargetIconCtx.moveTo(sWCut2, sHeight - sHCut2)
      attackTargetIconCtx.lineTo(sWCut3, sHeight - sHCut3)

      attackTargetIconCtx.stroke()

      ctx1.filter = 'drop-shadow(0px 0px 1px black)'
      ctx1.drawImage(
        attackTargetIconBuffer,
        dx,
        dy,
        attackTargetIconBuffer.width,
        attackTargetIconBuffer.height
      )
      ctx1.filter = 'none'
    }

    // reset screen
    elem.width = renderWidthPx
    elem.height = renderHeightPx
    ctx0.clearRect(0, 0, renderWidthPx, renderHeightPx)
    disableCanvasSmoothing(ctx0)

    // write buffer1 to screen
    // console.log(`${name}[${entityLayerIdListText}] write buffer1 to screen`)
    ctx0.drawImage(buffer1, pan_x - canvas_edge_buffer, pan_y - canvas_edge_buffer)
    // ctx0.drawImage(arrowBuffer, pan_x - canvas_edge_buffer, pan_y - canvas_edge_buffer)
    ctx0.globalAlpha = 0.75
    ctx0.filter = 'drop-shadow(5px 5px 10px black)'
    ctx0.drawImage(arrowBuffer, pan_x - canvas_edge_buffer, pan_y - canvas_edge_buffer)

    // ctx0.drawImage(arrowBuffer, pan_x - canvas_edge_buffer, pan_y - canvas_edge_buffer)
    ctx0.globalAlpha = 1
    ctx0.filter = 'none'
    ctx0.drawImage(unitBuffer, pan_x - canvas_edge_buffer, pan_y - canvas_edge_buffer)
  }

  bindCanvasToEngine(engine, elem, render)
}

function drawRedRoundRect(
  ctx: EitherRenderingContext2D,
  arrowWidth: number,
  canvas_edge_buffer: number,
  tile_size_plus_gap: number,
  gap1: number,
  rrRadii: number,
  x1: number,
  y1: number
) {
  const dx1: number = canvas_edge_buffer + x1 * tile_size_plus_gap + gap1
  const dy1: number = canvas_edge_buffer + y1 * tile_size_plus_gap + gap1
  const w = arrowWidth
  const h = arrowWidth
  // if (step !== lastStep) {
  ctx.beginPath()
  ctx.roundRect(dx1, dy1, w, h, rrRadii)
  ctx.fill()
  // }
}

import { Engine } from '../../core/engine/Engine.type'
import onWindowEvent from '../../onWindowEvent'

export default function registerWheelEvent(engine: Engine) {
  function onWheel(event: WheelEvent) {
    event.stopPropagation()
    const { viewCtx } = engine

    // engine.viewCtx.zoomQueue.push({
    //   clientX: event.clientX,
    //   clientY: event.clientY,
    //   deltaY: event.deltaY,
    //   tile_size: viewCtx.tile_size,
    //   pan_x: viewCtx.pan_x,
    //   pan_y: viewCtx.pan_y,
    // })

    engine.viewCtx.zoomQueue.push([
      event.clientX,
      event.clientY,
      event.deltaY,
      viewCtx.mapWidthPx,
      viewCtx.mapHeightPx,
      viewCtx.pan_x,
      viewCtx.pan_y,
    ])

    // console.table(structuredClone(engine.viewCtx.zoomQueue))
  }

  onWindowEvent('wheel', onWheel)
}

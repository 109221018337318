import { Key } from "@solid-primitives/keyed"
import { Accessor, Component, Show, useContext } from "solid-js"
import { Engine } from "../../lib/core/engine/Engine.type"
import createSampleEntity from "../../lib/core/entity/create_sample_entity"
import { LightTankEntityTypeMeta } from "../../lib/core/entity/EntityType/LightTank"
import { Player } from "../../lib/core/player/Player.type"
import createAuthProfileSignal from "../../rx/shared/profile/createAuthProfileSignal"
import SelectPlayerKindDropDownButton from "../Btn/SelectPlayerKindDropDownButton"
import EngineContext from "../EngineContext"
import SingleSpriteIcon from "../MapEditor/SingleSpriteIcon"
import TextMuted from "../Util/TextMuted"

const CreateGamePlayersEditor: Component = () => {
  const engine : Engine = useContext(EngineContext)

  const iconEntityType = LightTankEntityTypeMeta

  const profile = createAuthProfileSignal()[0]

  function isCurrentPlayer(player: Player): boolean {
    const profileId = profile()?.id
    if (profileId) {
      return player.profile_id === profileId
    }
    return false
  }

  // const [isDropDownOpen, setIsDropDownOpen] = createSignal<boolean>(false)
  // const [selectedPlayerId, setSelectedPlayerId] = createSignal<PlayerIdOrNull>(props.value)

  // const selectedPlayer = createMemo<Nullable<Player>>(() => {
  //   const playerId = selectedPlayerId()
  //   return props.players.find((player) => player.id === playerId)
  // })

  // const engine: Engine = createMutable(createEngineForUI())

  // onMount(() => {
  //   const firstPlayer = engine.state.players[0]
  //   firstPlayer.kind = PlayerKind.Human
  //   if (profile()?.dname) {
  //     firstPlayer.dname = profile()?.dname as string
  //   }
  //   if (profile()?.id) {
  //     firstPlayer.profile_id = profile()?.id
  //   } else {
  //     firstPlayer.is_local = true
  //   }
  // })

  // createEffect(() => {
  //   engine.state.players = [
  //     ...props.state.players
  //   ]
  //   // console.log('createEffect.engine.state.players', structuredClone(unwrap(engine.state.players)))
  // })

  // bindEngineToComponent(engine)

  return <ul class="list-unstyled">
    <Key each={engine.state.players} by="id">
      {(player: Accessor<Player>) => <li>
        <SingleSpriteIcon
          tileSize={24}
          entity={createSampleEntity(iconEntityType, player().id)}
          style={{ 'padding-right': '5px' }}
        />
        {player().dname}{' '}
        <small class="text-muted">({player().id})</small>
        {' '}<SelectPlayerKindDropDownButton player={player()} />{' '}
        <Show when={isCurrentPlayer(player())}>
          {TextMuted('(YOU)')}
        </Show>
      </li>}
    </Key>
    </ul>

}

export default CreateGamePlayersEditor


import type { TilePositionXY } from '../../../core/tile_position_xy/TilePositionXY.type'
import { floor, PI } from '../../../core/util/math'
import type { EitherRenderingContext2D } from '../../get_canvas_2d_context'

export default function drawUnitPathArrowHeadToPosition(
  position1: TilePositionXY,
  position2: TilePositionXY,
  context: EitherRenderingContext2D,
  tile_size: number,
  tile_size_plus_gap: number,
  halfTileSize: number
) {
  // Define the arrow properties
  const arrowWidth = tile_size // Width of the arrowhead
  const arrowLength = floor(tile_size * (3 / 4))
  const arrowHeadBaseOffset = floor((tile_size - arrowLength) / 2)

  // Calculate the direction of the movement
  const deltaX = position2.x - position1.x
  const deltaY = position2.y - position1.y

  // Determine the direction (Left, Right, Up, Down)
  let angle = 0

  if (deltaX === 1 && deltaY === 0) {
    // Moving right
    angle = 0
  } else if (deltaX === -1 && deltaY === 0) {
    // Moving left
    angle = PI // 180 degrees
  } else if (deltaX === 0 && deltaY === 1) {
    // Moving down
    angle = PI / 2 // 90 degrees
  } else if (deltaX === 0 && deltaY === -1) {
    // Moving up
    angle = -PI / 2 // -90 degrees
  }

  // Center the arrow at position2
  const centerX = position2.x * tile_size_plus_gap + halfTileSize
  const centerY = position2.y * tile_size_plus_gap + halfTileSize

  // Start drawing the arrow
  context.save() // Save the current canvas state
  context.translate(centerX, centerY) // Move the origin to the center of position2
  context.rotate(angle) // Rotate the context to point the arrow in the right direction

  if (deltaX === 1 && deltaY === 0) {
    // console.log('moving right')
    context.translate(halfTileSize, 0)
  } else if (deltaX === -1 && deltaY === 0) {
    // console.log('moving left')
    context.translate(-halfTileSize, -tile_size)
  } else if (deltaX === 0 && deltaY === 1) {
    // console.log('moving down')
    context.translate(halfTileSize, -tile_size)
  } else if (deltaX === 0 && deltaY === -1) {
    // console.log('moving up')
    context.translate(-halfTileSize, 0)
  }
  context.translate(halfTileSize, halfTileSize) // Move the origin to the center of position2
  context.translate(-arrowHeadBaseOffset, 0) // Start the arrow past the border of the tile
  context.clearRect(-halfTileSize, -halfTileSize, halfTileSize, tile_size)

  // Draw the arrowhead
  context.beginPath()
  context.moveTo(0, 0) // Start of the arrowhead (at the center)
  context.lineTo(-arrowLength, -arrowWidth / 2) // Top point of the arrowhead
  context.lineTo(-arrowLength, arrowWidth / 2) // Bottom point of the arrowhead
  context.closePath()
  context.fillStyle = 'red'
  context.fill()

  context.restore() // Restore the canvas state
}

import { Button } from "solid-bootstrap"
import { Component, createMemo, createSignal, useContext } from "solid-js"
import { Engine } from "../../lib/core/engine/Engine.type"
import { Player } from "../../lib/core/player/Player.type"
import { PlayerKind } from "../../lib/core/player/PlayerKind"
import { createClaimPlayerSlotAction } from "../../lib/core/state/flux/action/Lobby/ClaimPlayerSlotAction"
import { createKickPlayerSlotAction } from "../../lib/core/state/flux/action/Lobby/KickPlayerSlotAction"
import { createLeavePlayerSlotAction } from "../../lib/core/state/flux/action/Lobby/LeavePlayerSlotAction"
import { createSetBotPlayerSlotAction } from "../../lib/core/state/flux/action/Lobby/SetBotPlayerSlotAction"
import dispatchClient from "../../lib/core/state/flux/dispatchClient"
import onDocumentEvent from "../../lib/onDocumentEvent"
import createAuthProfileSignal from "../../rx/shared/profile/createAuthProfileSignal"
import { createIsGuestMemo } from "../../rx/shared/session/createIsGuestMemo"
import EngineContext from "../EngineContext"

interface Props {
  // onChange: (player: Player) => void
  player: Player,
}

const kindTextMap : Record<PlayerKind, string> = {
  [PlayerKind.OpenSlot]: 'Open',
  [PlayerKind.ReservedSlot]: 'Reserved',
  [PlayerKind.Human]: 'Human',
  [PlayerKind.Bot]: 'Bot',
  [PlayerKind.Spectator]: 'Spectator',
}
// const kindsList : Array<PlayerKind> = [
//   PlayerKind.OpenSlot,
//   PlayerKind.ReservedSlot,
//   PlayerKind.Human,
//   PlayerKind.Bot,
//   PlayerKind.Spectator,
// ]

const SelectPlayerKindDropDownButton: Component<Props> = (props) => {
  const engine : Engine = useContext(EngineContext)
  const profile = createAuthProfileSignal()[0]
  const isGuest = createIsGuestMemo()

  const [isDropDownOpen, setIsDropDownOpen] = createSignal<boolean>(false)

  const isCurrentPlayer = createMemo<boolean>((): boolean => {
    return props.player.profile_id === profile()?.id
  })

  const isJoined = createMemo<boolean>((): boolean => {
    return !!engine.state.players.find(p => p.profile_id === profile()?.id)
  })

  const isSlotOpen = createMemo<boolean>((): boolean => {
    return props.player.kind === PlayerKind.OpenSlot
  })

  const canKick = createMemo<boolean>((): boolean => {
    return props.player.kind !== PlayerKind.OpenSlot
  })

  // const isHumanDisabled = createMemo<boolean>((): boolean => {
  //   if (props.player.kind === PlayerKind.Human) {
  //     return true
  //   }
  //   return false
  // })

  return <div
    class="dropdown d-inline"
    ref={(dropDropComponentElem) => {
      function closeBecauseClickedOutside (event: MouseEvent) {
        if (isDropDownOpen()) {
          const target = event.target
          if (target !== dropDropComponentElem && !dropDropComponentElem.contains(target as Node)) {
            setIsDropDownOpen(false)
          }
        }
      }
    
      onDocumentEvent('click', closeBecauseClickedOutside)
    }}
  >
    <button type="button" aria-expanded={isDropDownOpen()}
      class="btn btn-secondary btn-sm"
      classList={{
        'dropdown-toggle': !isGuest(),
        'disabled': isGuest(),
      }}
      onClick={() => { setIsDropDownOpen(!isDropDownOpen()) }}
    >
      {kindTextMap[props.player.kind] || 'Unknown'}
    </button>
    <ul
      classList={{ 'dropdown-menu': true, show: isDropDownOpen() && !isGuest(), 'w-100': true }}
    >
      {canKick() && <Button class="dropdown-item"
        name="kick"
        onClick={() => {
          // console.log('kick')
          if (isCurrentPlayer()) {
            dispatchClient(engine, createLeavePlayerSlotAction(props.player.id))
          } else {
            dispatchClient(engine, createKickPlayerSlotAction(props.player.id))
          }
          setIsDropDownOpen(false)
        }}
      >{isCurrentPlayer() ? 'Leave' : 'Kick'}</Button>}
      {isSlotOpen() && <Button class="dropdown-item"
        name={isJoined() ? 'move_here' : 'join_here'}
        onClick={() => {
          // console.log(isJoined() ? 'move_here' : 'join_here')
          dispatchClient(engine, createClaimPlayerSlotAction(profile()?.id, props.player.id))
          setIsDropDownOpen(false)
        }}
      >{isJoined() ? 'Move Here' : 'Join'}</Button>}
      {isSlotOpen() && <Button class="dropdown-item"
        name="add_bot"
        onClick={() => {
          // console.log('add_bot')
          dispatchClient(engine, createSetBotPlayerSlotAction(props.player.id))
          setIsDropDownOpen(false)
        }}
      >Bot</Button>}
    </ul>
  </div>
}

export default SelectPlayerKindDropDownButton


/**
 * This file is automatically generated.
 **/
import type { EntityTypeMeta } from '../EntityTypeMeta'

import {
  APCEntityTypeMeta,
  APCEntitySchema,
  APCEntity,
} from './EntityType/APC'
import {
  BuildingEntityTypeMeta,
  BuildingEntitySchema,
  BuildingEntity,
} from './EntityType/Building'
import {
  DesertEntityTypeMeta,
  DesertEntitySchema,
  DesertEntity,
} from './EntityType/Desert'
import {
  FactoryEntityTypeMeta,
  FactoryEntitySchema,
  FactoryEntity,
} from './EntityType/Factory'
import {
  ForestEntityTypeMeta,
  ForestEntitySchema,
  ForestEntity,
} from './EntityType/Forest'
import {
  HQEntityTypeMeta,
  HQEntitySchema,
  HQEntity,
} from './EntityType/HQ'
import {
  InfantryEntityTypeMeta,
  InfantryEntitySchema,
  InfantryEntity,
} from './EntityType/Infantry'
import {
  LightAntiAirEntityTypeMeta,
  LightAntiAirEntitySchema,
  LightAntiAirEntity,
} from './EntityType/LightAntiAir'
import {
  LightReconEntityTypeMeta,
  LightReconEntitySchema,
  LightReconEntity,
} from './EntityType/LightRecon'
import {
  LightTankEntityTypeMeta,
  LightTankEntitySchema,
  LightTankEntity,
} from './EntityType/LightTank'
import {
  MediumAntiAirEntityTypeMeta,
  MediumAntiAirEntitySchema,
  MediumAntiAirEntity,
} from './EntityType/MediumAntiAir'
import {
  MediumArtillaryEntityTypeMeta,
  MediumArtillaryEntitySchema,
  MediumArtillaryEntity,
} from './EntityType/MediumArtillary'
import {
  MediumCopterEntityTypeMeta,
  MediumCopterEntitySchema,
  MediumCopterEntity,
} from './EntityType/MediumCopter'
import {
  MediumRocketsEntityTypeMeta,
  MediumRocketsEntitySchema,
  MediumRocketsEntity,
} from './EntityType/MediumRockets'
import {
  MediumTankEntityTypeMeta,
  MediumTankEntitySchema,
  MediumTankEntity,
} from './EntityType/MediumTank'
import {
  MountainEntityTypeMeta,
  MountainEntitySchema,
  MountainEntity,
} from './EntityType/Mountain'
import {
  PlainEntityTypeMeta,
  PlainEntitySchema,
  PlainEntity,
} from './EntityType/Plain'
import {
  RoadEntityTypeMeta,
  RoadEntitySchema,
  RoadEntity,
} from './EntityType/Road'
import {
  SeaEntityTypeMeta,
  SeaEntitySchema,
  SeaEntity,
} from './EntityType/Sea'
import {
  SnowEntityTypeMeta,
  SnowEntitySchema,
  SnowEntity,
} from './EntityType/Snow'
import {
  TransportCopterEntityTypeMeta,
  TransportCopterEntitySchema,
  TransportCopterEntity,
} from './EntityType/TransportCopter'

const entityTypeMetaList : Array<EntityTypeMeta> = [
  APCEntityTypeMeta,
  BuildingEntityTypeMeta,
  DesertEntityTypeMeta,
  FactoryEntityTypeMeta,
  ForestEntityTypeMeta,
  HQEntityTypeMeta,
  InfantryEntityTypeMeta,
  LightAntiAirEntityTypeMeta,
  LightReconEntityTypeMeta,
  LightTankEntityTypeMeta,
  MediumAntiAirEntityTypeMeta,
  MediumArtillaryEntityTypeMeta,
  MediumCopterEntityTypeMeta,
  MediumRocketsEntityTypeMeta,
  MediumTankEntityTypeMeta,
  MountainEntityTypeMeta,
  PlainEntityTypeMeta,
  RoadEntityTypeMeta,
  SeaEntityTypeMeta,
  SnowEntityTypeMeta,
  TransportCopterEntityTypeMeta,
]

export const entitySchemaList = [
  APCEntitySchema,
  BuildingEntitySchema,
  DesertEntitySchema,
  FactoryEntitySchema,
  ForestEntitySchema,
  HQEntitySchema,
  InfantryEntitySchema,
  LightAntiAirEntitySchema,
  LightReconEntitySchema,
  LightTankEntitySchema,
  MediumAntiAirEntitySchema,
  MediumArtillaryEntitySchema,
  MediumCopterEntitySchema,
  MediumRocketsEntitySchema,
  MediumTankEntitySchema,
  MountainEntitySchema,
  PlainEntitySchema,
  RoadEntitySchema,
  SeaEntitySchema,
  SnowEntitySchema,
  TransportCopterEntitySchema,
]

export type EntitySchemaUnion = (typeof entitySchemaList)[number]


export type EntityUnion = APCEntity | BuildingEntity | DesertEntity | FactoryEntity | ForestEntity | HQEntity | InfantryEntity | LightAntiAirEntity | LightReconEntity | LightTankEntity | MediumAntiAirEntity | MediumArtillaryEntity | MediumCopterEntity | MediumRocketsEntity | MediumTankEntity | MountainEntity | PlainEntity | RoadEntity | SeaEntity | SnowEntity | TransportCopterEntity


export default entityTypeMetaList
import { FaSolidArrowsRotate } from 'solid-icons/fa'
import { Component, createMemo, createSignal, Match, Show, Switch, useContext, type Accessor } from 'solid-js'
import type { Engine } from '../../lib/core/engine/Engine.type'
import getPerTurnIncomeFromPlayerEnts from '../../lib/core/entity/getPerTurnIncomeFromPlayerEnts'
import getPlayerEntities from '../../lib/core/entity/getPlayerEntities'
import type { Player } from '../../lib/core/player/Player.type'
import { createEndTurnAction } from '../../lib/core/state/flux/action/Game/EndTurnAction'
import dispatchClient from '../../lib/core/state/flux/dispatchClient'
import { immutableEmptyArray } from '../../lib/lib'
import withBusy from '../../lib/withBusy'
import { createAuthPlayerMemo } from '../../rx/signal/createAuthPlayerSignal'
import type { Nullable } from '../../typescript'
import EngineContext from '../EngineContext'
import PerTurnIncome from './PerTurnIncome'
import PlayerMoneyCornerWidget from './PlayerMoneyCornerWidget'

const PlayerCornerWidget: Component = () => {
  const engine : Engine = useContext(EngineContext)
  const authPlayer = createAuthPlayerMemo(engine)

  const isAuthTurn = createMemo<boolean>((): boolean => {
    const { authPlayerId } = engine
    return (authPlayerId && authPlayerId === engine.state.turnPlayerId) as boolean
  })

  const turnPlayer = createMemo<Nullable<Player>>((): Nullable<Player> => {
    const { players, turnPlayerId } = engine.state
    return turnPlayerId ? players.find((p) => p.id === turnPlayerId) : null
  })
  const canEndTurn = createMemo<boolean>((): boolean => {
    return isAuthTurn() && true
  })

  const playerEnts = createMemo(() => {
    const { authPlayerId } = engine
    if (authPlayerId) {
      return getPlayerEntities(engine.state.ents, authPlayerId)
    }
    return immutableEmptyArray as unknown as ReturnType<typeof getPlayerEntities>
  })

  const perTurnIncome = createMemo<number>(() => {
    return getPerTurnIncomeFromPlayerEnts(playerEnts())
  })

  const [isBusy, setIsBusy] = createSignal<boolean>(false)
  const [isMoneyWidgetOpen, setIsMoneyWidgetOpen] = createSignal<boolean>(false)

  return (
    <Show when={authPlayer()} children={(player : Accessor<Player>) => (
      <>
        <div class="cw cw-player">
          <div class="text-break">{player().dname || player().dname_placeholder}</div>
          <div class="d-flex justify-content-between">
          <Switch>
            <Match when={isAuthTurn()}>
              <span class="fs-6">Your Turn</span>
              <Show when={canEndTurn()}>
                <button
                  class="btn btn-danger btn-sm"
                  classList={{'disabled': isBusy()}}
                  disabled={isBusy()}
                  onClick={withBusy(isBusy, setIsBusy, async () => {
                    await dispatchClient(engine, createEndTurnAction((player()).id))
                  })}
                ><FaSolidArrowsRotate />{' '}End Turn</button>
              </Show>
            </Match>
            <Match when={turnPlayer()}>
              <span class="fs-6 text-muted">Waiting on Other Player's Turn</span>
            </Match>
          </Switch>
          </div>
          <PerTurnIncome value={perTurnIncome()} player={player()} onClick={() => {
            setIsMoneyWidgetOpen(!isMoneyWidgetOpen())
          }}/>
        </div>
        <PlayerMoneyCornerWidget class="player1" when={isMoneyWidgetOpen()} setOpen={setIsMoneyWidgetOpen} player={player()}/>
      </>
    )}/>
  )
}

export default PlayerCornerWidget

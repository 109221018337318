import type { Engine } from "../engine/Engine.type"
import type { HasPlayerId } from "../player/has_player_id"
import { samePosition } from "../tile_position_xy/samePosition"
import getEntityTypeMetaById from "./getEntityTypeMetaById"
import type { HasTaxiID } from "./HasTaxiID"

export default function getTransportableDestPositionEntity(engine: Engine) {
  const { draftMove } = engine
  if (draftMove) {
    const { destPosition, unit } = draftMove
    const entityTypeMeta = getEntityTypeMetaById(unit.etype_id)
    const transportsEntityIdList = entityTypeMeta.transports
    const unitPlayerId = (unit as HasPlayerId).player_id
    if (destPosition && unitPlayerId && transportsEntityIdList) {
      return engine.state.ents.find(entity => {
        // cant transport what is already transported
        if ((entity as HasTaxiID).taxi_id) {
          return false
        }
        if ((entity as HasPlayerId).player_id === unitPlayerId) {
          if (transportsEntityIdList.includes(entity.etype_id)) {
            return samePosition(entity, destPosition)
          }
        }
      })
    }
  }
  return null
}